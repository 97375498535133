import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Container,
  FormControl,
  Button,
  Table,
  Spinner,
  Modal,
  Accordion,
  Card,
  Tabs,
  Tab
} from 'react-bootstrap'
import { NavLink } from "react-router-dom"
import {
  AiOutlineFileSearch,
  AiOutlineReload
} from 'react-icons/ai'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import ReactHtmlParser from 'react-html-parser'
import { ImageDrop } from 'quill-image-drop-module'
import Pagination from '../../Pagination/Pagination'

class Submissions extends PureComponent {
  constructor(props) {
    super(props)

    Quill.register('modules/imageDrop', ImageDrop)

    this.state = {
      submissions: {
        data: [],
        loading: false,
        loaded: false,
        error: '',
      },
      submissionUsers: {
        data: [],
        loading: false,
        loaded: false,
        error: ''
      },
      submissionFormTypes: {
        data: [],
        loading: false,
        loaded: false,
        error: ''
      },
      specificSubmission: {
        data: {},
        loading: false,
        loaded: false,
        error: '',
        saving: false,
      },
      submissionEvents: {
        data: [],
        loading: false,
        loaded: false,
        error: ''
      },
      searchParams: {
        userQuery: '',
        formTypeQuery: '',
        statusQuery: '',
        offset: 0,
      },
      userQuery: '',
      formTypeQuery: '',
      statusQuery: '',
      offset: 0,
      showSpecificSubmissionModal: false,
      selectedStatus: '',
      currentPage: 0,
      currentActivityPage: 0,
      activityOffset: 0,
      showCommentBox: false,
      commentValue: '',
      showEmailBox: false,
      emailParams: {
        subject: '',
        replyTo: '',
        email: ''
      },
      searchLimit: 10
    }

    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'link', 'image'],
      ],
      // imageDrop: {}
    }

    this.formats = {
      formats: ['bold', 'italic', 'underline', 'link', 'image']
    }
  }

  componentDidMount() {
    this.props.clearSpecificSubmission()
    this.props.searchSubmissionsRequest()
    this.props.submissionsClearUsers()
    this.props.submissionsGetFormTypesRequest()
    this.props.resetSubmissionEvents()
    this.handleParseUrl()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        submissions: nextProps.submissions,
        submissionUsers: nextProps.submissionUsers,
        submissionFormTypes: nextProps.submissionFormTypes,
        specificSubmission: nextProps.specificSubmission,
        submissionEvents: nextProps.submissionEvents,
      })
    }
  }

  handleResetQueries = () => {
    this.props.submissionsClearUsers()
    this.setState({
      searchParams: {
        userQuery: '',
        formTypeQuery: '',
        statusQuery: '',
        offset: 0
      }
    }, () => this.handleSearchSubmissions())
  }

  handleChangeQuery = e => {
    const { name, value } = e.target
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        [name]: value,
        offset: 0
      },
      currentPage: 0,
    }, () => this.handleSearchSubmissions())
  }

  handleSelectUser = userId => {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        userQuery: userId,
        offset: 0,
      },
      currentPage: 0,
    }, () => this.handleSearchSubmissions())
  }

  handleSearchSubmissions = () => {
    this.props.searchSubmissionsRequest(this.state.searchParams)
  }

  handleSearchActivities = () => {
    const { activityOffset, specificSubmission } = this.state
    this.props.getSubmissionEventsRequest(specificSubmission.data._id, activityOffset)
  }

  handleClearEmailParams = () => {
    this.setState({
      emailParams: {
        subject: '',
        replyTo: '',
        email: ''
      }
    })
  }

  handleToggleCommentBox = () => {
    this.setState({
      showCommentBox: !this.state.showCommentBox,
      showEmailBox: false,
    })
    this.handleClearEmailParams()
  }

  handleToggleEmailBox = () => {
    this.setState({
      showEmailBox: !this.state.showEmailBox,
      showCommentBox: false,
      commentValue: '',
    })
    this.handleClearEmailParams()
  }

  handleGetSpecificSubmission = submissionId => {
    this.setState({
      showSpecificSubmissionModal: true,
      currentActivityPage: 0,
      commentValue: '',
      commentBox: false,
    })
    this.props.getSpecificSubmissionRequest(submissionId)
    this.props.getSubmissionEventsRequest(submissionId)
  }

  handleSearchUsers = e => {
    const query = e.target.value
    if (query) {
      this.setState({
        searchParams: {
          [e.target.name]: query
        }
      }, () => this.props.submissionsSearchUsersRequest(query))
    } else {
      this.setState({
        searchParams: {
          ...this.state.searchParams,
          userQuery: '',
          offset: 0
        },
        currentPage: 0
      }, () => this.handleSearchSubmissions())
      this.props.submissionsClearUsers()
    }
  }

  handleCloseSpecificSubmissionModal = () => {
    this.setState({
      showSpecificSubmissionModal: false,
      showEmailBox: false,
      showCommentBox: false,
      commentValue: ''
    })
    this.handleClearEmailParams()
  }

  handleSelectStatus = e => {
    const newData = this.state.specificSubmission.data
    newData.status = e.target.value

    this.setState({
      specificSubmission: {
        ...this.state.specificSubmission
      }
    })
  }

  handleSetStatusOfSubmission = () => {
    const { status, _id } = this.state.specificSubmission.data
    const data = {
      status,
      _id
    }

    const params = this.state.searchParams

    this.props.setStatusOfSubmissionRequest(data, params)
  }

  formatDate = dateInput => {
    let date = new Date(dateInput)
    const month = date.toLocaleString("en-us", { month: "short" })
    const day = date.getDate()
    const year = date.getFullYear()
    return month + " " + day + ", " + year
  }

  handleClickSubmissionsPage = number => {
    this.setState({
      currentPage: number.selected,
      searchParams: {
        ...this.state.searchParams,
        offset: number.selected * this.state.searchLimit
      }
    }, () => this.handleSearchSubmissions())
}

  handleClickActivityPage = number => {
    this.setState({
      currentActivityPage: number.selected,
      activityOffset: number.selected * 10
    }, () => this.handleSearchActivities())
  }

  handleSearchSubmissionEvents = () => {
    this.props.getSubmissionEventsRequest(this.state.specificSubmission.data._id)
  }

  handlePostComment = () => {
    const { commentValue, specificSubmission } = this.state
    this.props.addSubmissionCommentRequest(specificSubmission.data._id, commentValue)
    this.setState({
      commentValue: ''
    })
  }

  handleOnChangeCommentBox = e => {
    if (e.length < 1000) {
      this.setState({
        commentValue: e
      })
    }
  }

  handleOnChangeEmailBox = e => {
    if (e.length < 1000) {
      this.setState({
        emailParams: {
          ...this.state.emailParams,
          email: e
        }
      })
    }
  }

  handleOnChangeEmailParams = e => {
    this.setState({
      emailParams: {
        ...this.state.emailParams,
        [e.target.name]: e.target.value
      }
    })
  }

  handleEmailApplicant = () => {
    const { emailParams, specificSubmission } = this.state
    const body = {
      submission_id: specificSubmission.data._id,
      subject: emailParams.subject,
      replyTo: emailParams.replyTo,
      content: emailParams.email
    }

    this.props.emailApplicantRequest(body)
    this.handleClearEmailParams()
  }

  handleParseUrl = () => {
    const { search } = this.props.history.location

    if (search) {
      if (search.includes('?user_id')) {
        const splitA = search.split('?user_id=')
        const userId = splitA[1]

        this.setState({
          searchParams: {
            ...this.state.searchParams,
            userQuery: userId
          }
        }, () => {
          this.handleSearchSubmissions()
          this.props.submissionsSearchUsersRequest(userId)
        })
      }
    }
  }

  /****************************************************************************/

  renderLoading = () => (
    <Row noGutters className='app-spinner'>
      <Spinner animation='border' className='common-grey-spinner' />
    </Row>
  )

  renderLoadingUsers = () => (
    <Row noGutters className='submission-loading-users'>
      <Spinner animation='border' className='common-grey-spinner' />
    </Row>
  )

  renderFormTypeOption = formType => {
    const { name, _id } = formType
    return <option key={_id} value={_id}>{name}</option>
  }

  renderSearchBar = () => {
    const { submissionFormTypes, searchParams } = this.state

    let formTypeOptions
    if (submissionFormTypes.loaded) {
      formTypeOptions = submissionFormTypes.data.map(formType => this.renderFormTypeOption(formType))
    } else if (submissionFormTypes.loading) {
      formTypeOptions = this.renderLoading()
    }

    return (
      <Row noGutters className='submissions-search-bar'>

        <Col>
          <label className='submission-form-control-label'>User:</label>
          <FormControl
            size='sm'
            name='userQuery'
            value={searchParams.userQuery}
            placeholder='Search for users...'
            onChange={this.handleSearchUsers}
            className='submission-form-control' />
        </Col>

        <Col>
          <label className='submission-form-control-label'>Form Type:</label>
          <FormControl
            size='sm'
            value={searchParams.formTypeQuery}
            as='select'
            name='formTypeQuery'
            onChange={this.handleChangeQuery}
            className='submission-form-control'>
            <option value=''>Select a form type...</option>
            {formTypeOptions}
          </FormControl>

        </Col>

        <Col>
          <label className='submission-form-control-label'>Status:</label>
          <FormControl
            size='sm'
            as='select'
            value={searchParams.statusQuery}
            name='statusQuery'
            onChange={this.handleChangeQuery}
            className='submission-form-control'>
            <option value=''>Status...</option>
            <option value='approved'>Approved</option>
            <option value='rejected'>Rejected</option>
            <option value='submitted'>Submitted</option>
            <option value='pending'>Pending</option>
            <option value='manual'>Manual</option>
          </FormControl>

        </Col>
      </Row>
    )
  }

  renderUserSearchBar = () => {
    const { submissionUsers } = this.state

    let users
    if (submissionUsers.loaded) {
      users = submissionUsers.data.docs.map(user => this.renderIndividualUser(user))
    } else if (submissionUsers.loading) {
      users = this.renderLoadingUsers()
    }

    return (
      <Row noGutters>
        {users}
      </Row>
    )
  }

  renderIndividualUser = user => {
    const { email, _id, role } = user
    const { userQuery } = this.state.searchParams

    let className
    if (_id === userQuery) {
      className = 'submission-user-selector selected'
    } else {
      className = 'submission-user-selector'
    }

    return (
      <div
        key={_id}
        onClick={() => this.handleSelectUser(_id)}
        className={className}>
        {email}
      </div>
    )
  }

  renderTable = () => {
    const { submissions } = this.state

    let body
    if (submissions.loaded) {
      if (submissions.data.docs.length > 0) {
        body = submissions.data.docs.map(submission => this.renderIndividualSubmission(submission))
      } else {
        body = (
          <tr>
            <td colSpan='4'>
              <Row noGutters className='common-center-justified'>
                No submissions found
              </Row>
            </td>
          </tr>
        )
      }

    } else if (submissions.loading) {
      body = (
        <tr>
          <td colSpan='4'>
            {this.renderLoading()}
          </td>
        </tr>
      )
    }

    return (
      <Table bordered className='submissions-table'>

        <thead>
          <tr>
            <th>User</th>
            <th>Form Name</th>
            <th>Status</th>
            <th>Date Submitted</th>
          </tr>
        </thead>

        <tbody>
          {body}
        </tbody>

      </Table>
    )
  }

  renderIndividualSubmission = submission => {
    const { _id, status, form_id, user_id, dateSubmitted } = submission

    let date
    if (dateSubmitted) {
      date = this.formatDate(dateSubmitted)
    } else {
      date = '** No dateSubmitted **'
    }

    let formId
    let formName
    if (form_id) {
      formId = form_id._id
      formName = form_id.name
    }

    let userId
    let userEmail
    if (user_id) {
      userId = user_id._id
      userEmail = user_id.email
    }

    return (
      <tr key={_id}>
        <td>{userEmail || 'No user email provided'}</td>
        <td>{formName || 'No form name provided'}</td>
        <td>{status}</td>
        <td>
          <Row noGutters className='individual-form-status-row'>
            {date}

            <AiOutlineFileSearch
              onClick={() => this.handleGetSpecificSubmission(_id)}
              className='individual-form-icon' />
          </Row>

        </td>
      </tr>
    )
  }

  renderIdentityMind = data => {
    const { mtid, state, status, tid } = data
    const description = status[0].description
    const statusState = status[0].state

    return (
      <Accordion className='identity-mind-tag'>
        <Card>
          <Card.Header>
            <Accordion.Toggle
              className='identity-mind-toggle'
              as={Button} variant='link' eventKey='0'>
              Show IDM
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <Col>
                <Row noGutters className='specific-submission-field'>
                  <Col>
                    <label className='specific-submission-field-label'>
                      mtid
                    </label>

                    <div className='specific-submission-content'>
                      {mtid}
                    </div>
                  </Col>
                </Row>

                <Row noGutters className='specific-submission-field'>
                  <Col>
                    <label className='specific-submission-field-label'>
                      tid
                    </label>

                    <div className='specific-submission-content'>
                      {tid}
                    </div>
                  </Col>
                </Row>

                <Row noGutters className='specific-submission-field'>
                  <Col>
                    <label className='specific-submission-field-label'>
                      state
                    </label>

                    <div className='specific-submission-content'>
                      {state}
                    </div>
                  </Col>
                </Row>

                <Row noGutters className='specific-submission-field'>
                  <Col>
                    <label className='specific-submission-field-label'>
                      Status Description
                    </label>

                    <div className='specific-submission-content'>
                      {description}
                    </div>
                  </Col>
                </Row>

                <Row noGutters className='specific-submission-field'>
                  <Col>
                    <label className='specific-submission-field-label'>
                      Status State
                    </label>

                    <div className='specific-submission-content'>
                      {statusState}
                    </div>
                  </Col>
                </Row>
              </Col>

            </Card.Body>
          </Accordion.Collapse>

        </Card>
      </Accordion>
    )
  }

  renderSpecificSubmissionModal = () => {
    const { showSpecificSubmissionModal, specificSubmission } = this.state

    if (specificSubmission.loaded) {
      const { fields, status, _id, name, dateSubmitted, user_id, identitymind, flags } = specificSubmission.data

      let userEmail
      if (user_id) {
        if (user_id.email) {
          userEmail = <b>{user_id.email}</b>
        } else {
          userEmail = '** no user_id.email **'
        }
      }

      let date
      if (dateSubmitted) {
        date = <b>{this.formatDate(dateSubmitted)}</b>
      } else {
        date = '** no dateSubmitted **'
      }

      let renderFields
      if (specificSubmission.loaded) {
        renderFields = fields.map(field => this.renderIndividualSubmissionField(field))
      } else if (specificSubmission.loading) {
        renderFields = this.renderLoading()
      }

      let identityMindTag
      if (identitymind) {
        identityMindTag = this.renderIdentityMind(identitymind)
      }

      let flaggedFieldsTab
      if (flags.length > 0) {
        flaggedFieldsTab = (
          <Tab
            disabled={flags.length == 0 ? true : false}
            className='admin-tab' eventKey='flagged' title='Flagged'>
            {flags.map(field => this.renderIndividualFlaggedField(field))}
          </Tab>
        )
      }

      return (
        <Modal
          centered
          className='common-modal submission-modal'
          show={showSpecificSubmissionModal}
          onHide={this.handleCloseSpecificSubmissionModal}>

          <Modal.Header closeButton>
            <Row noGutters>
              <Col>
                <Modal.Title>{name}</Modal.Title>
                <Row noGutters className='submission-modal-submitted-by'>
                  Submitted by {userEmail} on {date}
                </Row>
                <Row noGutters className='submission-modal-submitted-by'>
                  Current status: <b>{status}</b>
                </Row>
              </Col>
            </Row>
          </Modal.Header>

          <Modal.Body>
            <Tabs defaultActiveKey='all-fields' className='admin-tabs'>
              <Tab className='admin-tab' eventKey='all-fields' title='All Fields'>
                {identityMindTag}
                {renderFields}
              </Tab>
              {flaggedFieldsTab}
              {this.renderActivityTab()}

            </Tabs>

          </Modal.Body>

          <Modal.Footer>
            <Col sm={6} className='submission-modal-column'>

              <Row noGutters>
                <label>Set Status:</label>
                <Col>
                  <FormControl
                    className='submission-form-control'
                    as='select'
                    onChange={this.handleSelectStatus}
                    value={status}
                    size='sm'>
                    <option value=''>Choose a status...</option>
                    <option value='approved'>Approved</option>
                    <option value='rejected'>Rejected</option>
                    <option value='submitted'>Submitted</option>
                    <option value='pending'>Pending</option>
                  </FormControl>
                </Col>

                <Button
                  className='teal-button'
                  size='sm'
                  onClick={this.handleSetStatusOfSubmission}>
                  Save
                </Button>
              </Row>
            </Col>
          </Modal.Footer>

        </Modal>
      )
    }
  }

  renderActivityTab = () => {
    const { data, loading, loaded } = this.state.submissionEvents
    const { showCommentBox, currentActivityPage, commentValue, showEmailBox, emailParams } = this.state

    let commentBox
    if (showCommentBox) {
      commentBox = (
        <Row noGutters className='activity-tab-box-row'>
          <Col>
            <Row noGutters className='submissions-email-row'>
              <label className='submissions-quill-label'>
                Comment:
              </label>
              <ReactQuill
                modules={this.modules}
                formats={this.formats}
                onChange={this.handleOnChangeCommentBox}
                defaultValue={commentValue}
                className='field-description-react-quill'/>
            </Row>
            <Row noGutters className='activity-tab-post-button-row'>
              <Button
                onClick={this.handlePostComment}
                className='teal-button activity-tab-post-button' size='sm' disabled={!commentValue ? true : false}>
                Post
              </Button>
            </Row>
          </Col>
        </Row>
      )
    }

    let emailBox
    if (showEmailBox) {
      emailBox = (
        <Row noGutters className='activity-tab-box-row'>
          <Col>
            <Row noGutters className='submissions-email-row'>
              <label className='submissions-email-label'>
                Subject:
              </label>
              <FormControl
                name='subject'
                placeholder='Subject...'
                onChange={this.handleOnChangeEmailParams}
                value={emailParams.subject}
                size='sm' className='submissions-email-formControl'/>
            </Row>

            <Row noGutters className='submissions-email-row'>
              <label className='submissions-email-label'>
                Reply to:
              </label>
              <FormControl
                name='replyTo'
                placeholder='Reply to...'
                onChange={this.handleOnChangeEmailParams}
                value={emailParams.replyTo}
                size='sm' className='submissions-email-formControl'/>
            </Row>

            <Row noGutters className='submissions-email-row'>
              <label className='submissions-quill-label'>
                Email:
              </label>
              <ReactQuill
                modules={this.modules}
                formats={this.formats}
                onChange={this.handleOnChangeEmailBox}
                defaultValue={emailParams.email}
                className='field-description-react-quill'/>
            </Row>

            <Row noGutters className='activity-tab-post-button-row'>
              <Button
                className='teal-button activity-tab-post-button' size='sm'
                onClick={this.handleEmailApplicant}
                disabled={(!emailParams.email || !emailParams.subject || !emailParams.replyTo) ? true : false}>
                Send Email
              </Button>
            </Row>

          </Col>

        </Row>
      )
    }

    let content
    if (loaded) {
      content = (
        <Tab className='admin-tab' eventKey='activity' title='Activity'>
          <AiOutlineReload
            onClick={this.handleSearchSubmissionEvents}
            className='activity-reset-button' />
          {data.data.map(activity => this.renderIndividualSubmissionActivity(activity))}
          {this.renderActivitiesPaginate(data)}

          {commentBox}
          {emailBox}

          <Row noGutters className='activity-button-row'>
            <Button className='teal-button' size='sm' onClick={this.handleToggleCommentBox}>
              Add Comment
            </Button>
            <Button className='teal-button' size='sm' onClick={this.handleToggleEmailBox}>
              Email Applicant
            </Button>
          </Row>
        </Tab>
      )
    } else if (loading) {
      content = (
        <Tab className='admin-tab' eventKey='activity' title='Activity'>
          {this.renderLoading()}
        </Tab>
      )
    }

    return content
  }

  renderIndividualSubmissionActivity = activity => {
    const { date, submission_id, message, eventType, data } = activity
    const formattedDate = this.formatDate(date)

    let content
    if (eventType == 'comment') {
      content = ReactHtmlParser(data)
    } else if (eventType == 'email') {
      content = ReactHtmlParser(data.content)
    }

    let comment
    if (eventType == 'comment' || eventType == 'email') {

      comment = (
        <Accordion className='identity-mind-tag'>
          <Card>
            <Card.Header>
              <Accordion.Toggle
                className='identity-mind-toggle'
                as={Button} variant='link' eventKey={submission_id}>
                See {eventType}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={submission_id}>
              <Card.Body>
                <Row noGutters className='comment'>
                  {content}
                </Row>
              </Card.Body>
            </Accordion.Collapse>

          </Card>
        </Accordion>
      )
    }

    return (
      <Row noGutters className='activity-individual-submission' key={submission_id}>
        <Col>
          <label>{formattedDate}</label>
          <div className='message'>{message}</div>
          {comment}
        </Col>
      </Row>
    )
  }

  renderIndividualSubmissionField = field => {
    const { fieldName, fieldType, value, data } = field

    let content
    let displayName = (
      <label className='specific-submission-field-label'>
        {fieldName || 'No fieldName given'}
      </label>
    )

    if (fieldType === 'image' || fieldType === 'e-signature') {
      content = this.renderImageField(field)
    } else if (fieldType === 'document') {
      content = this.renderDocumentField(field)
    } else if (fieldType === 'date') {
      content = this.renderDateField(field)
    } else if (fieldType === 'branch' && field.value) {
      content = this.renderBranchField(field)
    } else if (fieldType === 'checkbox') {
      content = this.renderCheckboxField(field)
    } else if (fieldType === 'file') {
      content = this.renderFileField(field)
    } else if (fieldType == 'idmplugin') {
      displayName = ''
      content = data.map(subfield => this.renderIDMSubfield(subfield))
    } else if(fieldType == "display") {
      content = ''
      displayName = ''
    } else {
      content = this.renderField(field)
    }

    return (
      <Row noGutters key={fieldName} className='specific-submission-field'>
        <Col>
          {displayName}
          {content}
        </Col>
      </Row>
    )
  }

  renderIDMSubfield = subfield => {
    const { type, label, data } = subfield

    let userResponse
    if (type == 'file') {
      userResponse = <img className='specific-submission-image' src={data} />
    } else {
      userResponse = data
    }
    return (
      <Row noGutters key={label} className='specific-submission-field'>
        <Col>
          <label className='specific-submission-field-label'>
            {label}
          </label>

          <div className='specific-submission-content'>
            {userResponse}
          </div>
        </Col>
      </Row>
    )
  }

  renderBranchField = field => {
    if (!field.value) {
      return
    }

    const subfield = field.subfields[field.value]

    return (
      <div className='submission-subfield-container'>
        <label className='specific-submission-field-label'>
          Branch: {subfield.name || 'No fieldName given'}
        </label>
        {subfield.data.map(subfield => this.renderIndividualSubmissionField(subfield))}
      </div>
    )
  }

  renderCheckboxField = field => {
    const { fieldName, fieldType, subfields } = field;

    return (
      <div className='submission-subfield-container'>
        {subfields.map(subfield => this.renderIndividualSubmissionField(subfield))}
      </div>
    )
  }

  renderField = field => {
    const { fieldName, fieldType, value } = field

    return (
      <div className='specific-submission-content'>
        {value || '** This field left blank **'}
      </div>
    )
  }

  renderIndividualFlaggedField = field => {
    const { fieldName, fieldType, value } = field

    let content
    if (fieldType === 'image' || fieldType === 'e-signature') {
      content = this.renderImageField(field)
    } else if (fieldType === 'document') {
      content = this.renderDocumentField(field)
    } else if (fieldType === 'date') {
      content = this.renderDateField(field)
    } else if (fieldType === 'file') {
      content = this.renderFileField(field)
    } else {
      content = this.renderField(field)
    }

    return (
      <Row noGutters key={fieldName} className='specific-submission-field-flagged'>
        <Col>
          <label className='specific-submission-field-label'>
            {fieldName || 'No fieldName given'}
          </label>

          {content}
        </Col>
      </Row>
    )
  }

  renderImageField = field => {
    const { fieldName, fieldType, value } = field

    let content
    if (value) {
      content = <img className='specific-submission-image' src={value} />
    } else {
      content = '** This field left blank **'
    }

    return (
      <div className='specific-submission-content'>
        {content}
      </div>
    )
  }

  renderDocumentField = field => {
    const { fieldName, fieldType, value } = field

    let content
    if (value) {
      content = (
        <a
          target='_blank'
          href={value}
          className='registration-document-preview'>
          Click here to view this document
        </a>
      )
    } else {
      content = '** This field left blank **'
    }

    return (
      <div className='specific-submission-content'>
        {content}
      </div>
    )
  }

  renderFileField = field => {
    const { fieldName, fieldType, value } = field

    let content
    if (value) {
      if (value.includes('.jpg') || value.includes('.png')) {
        content = <img className='specific-submission-image' src={value} />
      } else if (value.includes('.pdf')) {
        content = (
          <a
            target='_blank'
            href={value}
            className='registration-document-preview'>
            Click here to view this document
          </a>
        )
      } else {
        content = (
          <a
            target='_blank'
            href={value}
            className='registration-document-preview'>
            Click here to view this file
          </a>
        )
      }
    } else {
      content = '** This field left blank **'
    }

    return (
      <div className='specific-submission-content'>
        {content}
      </div>
    )
  }

  renderDateField = field => {
    const { fieldName, fieldType, value } = field

    let content
    if (value) {
      content = this.formatDate(value)
    } else {
      content = '** This field left blank **'
    }

    return (
      <div className='specific-submission-content'>
        {content}
      </div>
    )
  }

  renderResetQueriesButton = () => {
    return (
      <AiOutlineReload
        onClick={this.handleResetQueries}
        className='submissions-reset-queries-button' />
    )
  }

  renderSubmissionsPaginate = () => {
    const { docs, limit, total } = this.state.submissions.data
    const totalPages = Math.ceil(total / limit) || 1
    return (
      <Pagination
        totalPages={totalPages}
        handlePageClick={this.handleClickSubmissionsPage}
        dataPageNumber={this.state.currentPage}
      />
    )
  }

  renderActivitiesPaginate = (data) => {
    const { docs, limit, total } = data
    const totalPages = Math.ceil(total / limit) || 1
    return (
      <Pagination
        totalPages={totalPages}
        handlePageClick={this.handleClickActivityPage}
        dataPageNumber={this.state.currentActivityPage}
      />
    )
  }

  render() {
    return (
      <Container fluid className='admin-content-container'>
        {this.renderSpecificSubmissionModal()}

        <Row noGutters className='forms-header'>
          <h4>User Submissions</h4>
        </Row>

        <div className='forms-table-box'>
          {this.renderSearchBar()}
          {this.renderUserSearchBar()}
          {this.renderTable()}
          {this.renderResetQueriesButton()}
          {this.renderSubmissionsPaginate()}
        </div>

      </Container>
    )
  }
}

export default Submissions
