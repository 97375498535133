import React, { PureComponent } from 'react'
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Card,
  Image
} from 'react-bootstrap'
import { NavLink } from "react-router-dom"
import logo from '../../images/logo_fiui_full.png'
const EMAIL_REGEX =
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

class Forgot extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      emailError: false,
      requestSent: this.props.requestSent,
    }
  }

  componentDidMount() {
    this.props.resetForgot()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        requestSent: nextProps.requestSent
      })
    }
  }

  handleOnChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleForgot = () => {
    const { email } = this.state
    const body = { email }

    this.props.forgotRequest(body)
  }

  renderLogo = () => (
    <div className='login-logo-row'>
      <div className='login-logo-box'>
        <img src={logo} className='login-logo' />
      </div>
    </div>
  )

  checkEmailFormat = () => {
		this.setState(
			{
				...this.state,
				emailError: !EMAIL_REGEX.test(this.state.email),
			},
			() => console.log(this.state)
		);
	};

  renderForgotPassword = () => {
    const { email, emailError } = this.state
    const disabled = (email ? false : true) || emailError;

    return (
      <Row noGutters className='login-row'>
        <Col sm={8} md={6} lg={4} xl={4}>
          <Card className='login-box'>
            <Row noGutters>
              <Col className='login-content-col'>
                <Row noGutters className='login-header'>
                  <Image fluid src={logo} className='login-logo' />
                </Row>

                <Row noGutters className='login-box-padding login-formcontrol-row'>
                  <Col className='login-box-col'>
                    <Row noGutters className='login-welcome'>
                      <Col>
                        <h1>Welcome</h1>
                        <p>Please enter your email and a reset link will be sent to you</p>
                      </Col>
                    </Row>

                    <Row noGutters className='login-formcontrol-container'>
                      <div className='login-formcontrol-box'
                        id={
                          this.state.emailError &&
                          "formControl-error"
                        }
                      >
                        <div className='login-formcontrol-text'>
                          Email
                        </div>
                        <FormControl
                          size='sm'
                          name='email'
                          value={email}
                          onChange={this.handleOnChange}
                          onBlur={
                            this
                              .checkEmailFormat
                          }
                          className='login-formcontrol' />
                          {this.state.emailError && (
														<div className="system-form-control-error-text">
															Invalid email format
														</div>
													)}
                      </div>
                    </Row>

                    <Row noGutters className='login-button-row'>
                      <Button
                        size='sm'
                        disabled={disabled}
                        onClick={this.handleForgot}
                        className='teal-button'>
                        SEND
                      </Button>
                    </Row>

                    <div className='login-navlinks'>
                      <NavLink to='/login'>Log in</NavLink>
                      <NavLink to='/register'>Create an Account</NavLink>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }

  renderRequestSent = () => {
    return (
      <Row noGutters className='login-row'>
        <Col sm={8} md={6} lg={4} xl={4}>
          <Card className='login-box'>
            <Row noGutters>
              <Col className='login-content-col'>
                <Row noGutters className='login-header'>
                  <Image fluid src={logo} className='login-logo' />
                </Row>

                <Row noGutters className='login-box-padding login-formcontrol-row'>
                  <Col className='login-box-col'>
                    <Row noGutters className='login-welcome'>
                      <h5>A reset link has been sent to your email.</h5>
                    </Row>

                    <div className='login-navlinks'>
                      <NavLink to='/login'>Log in</NavLink>
                      <NavLink to='/register'>Create an Account</NavLink>
                    </div>

                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }

  render() {
    let content
    if (this.state.requestSent) {
      content = this.renderRequestSent()
    } else {
      content = this.renderForgotPassword()
    }

    return (
      <Container fluid className='login-container'>
        {content}
      </Container>
    )
  }
}

export default Forgot
