import { connect } from "react-redux"
import Submissions from './Submissions'
import {
  searchSubmissionsRequest,
  submissionsSearchUsersRequest,
  submissionsGetFormTypesRequest,
  submissionsClearUsers,
  getSpecificSubmissionRequest,
  clearSpecificSubmission,
  setStatusOfSubmissionRequest,
  getSubmissionEventsRequest,
  addSubmissionCommentRequest,
  emailApplicantRequest,
  resetSubmissionEvents,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  submissions: state.admin.submissions,
  submissionUsers: state.admin.submissionUsers,
  submissionFormTypes: state.admin.submissionFormTypes,
  specificSubmission: state.admin.specificSubmission,
  submissionEvents: state.admin.submissionEvents,
})

const mapDispatchToProps = {
  searchSubmissionsRequest,
  submissionsSearchUsersRequest,
  submissionsGetFormTypesRequest,
  submissionsClearUsers,
  getSpecificSubmissionRequest,
  clearSpecificSubmission,
  setStatusOfSubmissionRequest,
  getSubmissionEventsRequest,
  addSubmissionCommentRequest,
  emailApplicantRequest,
  resetSubmissionEvents,
}

export default connect(mapStateToProps, mapDispatchToProps)(Submissions)
