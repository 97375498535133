import { connect } from "react-redux"
import Preregistration from './Preregistration'
import captchaKeys from '../../captchaKeys.js'
import {
  submitPreregistration,
} from '../../redux/actions/register'

const mapStateToProps = state => ({
  siteKey: captchaKeys.siteKey,
  secretKey: captchaKeys.secretKey,
})

const mapDispatchToProps = {
  submitPreregistration,
}

export default connect(mapStateToProps, mapDispatchToProps)(Preregistration)
