import { connect } from "react-redux"
import Registration from './Registration'
import {
  getCheckListRequest,
  getSpecificUserFormRequest,
  editSpecificUserFormRequest,
  submitSpecificUserFormRequest,
  registrationUploadFileRequest,
  deleteUploadedFileRequest,
} from '../../redux/actions/register'
import {
  resendConfirmationEmailRequest,
  confirmEmailRequest,
} from '../../redux/actions/confirmEmail'
import { logoutRequest } from '../../redux/actions/login'
import { deleteAccountRequest } from '../../redux/actions/register'

const mapStateToProps = state => ({
  checkList: state.register.checkList,
  account: state.auth.account,
  specificUserForm: state.register.specificUserForm,
  currentForm: state.register.currentForm,
})

const mapDispatchToProps = {
  getCheckListRequest,
  getSpecificUserFormRequest,
  editSpecificUserFormRequest,
  submitSpecificUserFormRequest,
  registrationUploadFileRequest,
  deleteUploadedFileRequest,
  resendConfirmationEmailRequest,
  confirmEmailRequest,
  logoutRequest,
  deleteAccountRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration)
