import React, { PureComponent } from 'react'
import {
  Container,
  Row,
  Col,
  Spinner,
} from 'react-bootstrap'
import { ResponsiveLine } from '@nivo/line'
import { ResponsivePie } from '@nivo/pie'


class Dashboard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      dashboardStats: {
        data: {},
        loaded: false,
        loading: false,
        error: ''
      },

      graphData: {
        data: {},
        loaded: false,
        loading: false,
        error: ''
      }
    }
  }

  componentDidMount() {
    this.props.getDashboardStatsRequest()
    this.props.getGraphDataRequest()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        dashboardStats: nextProps.dashboardStats,
        graphData: nextProps.graphData,
      })
    }
  }

  handleChartData = data => {
    const chartData = [
      {
        data: data.dataArray,
        id: data.name,
      }
    ]

    return chartData
  }

  /***************************************************************************/


  renderApplicantsSection = () => {
    const { graphData, dashboardStats } = this.state

    let registrationChart
    let usersByRoleChart
    let usersByStatusChart
    if (graphData.loaded) {
      registrationChart = this.renderLineChart(graphData.data.registrations)
      usersByRoleChart = this.renderPieChart(graphData.data.UsersByRole)
      usersByStatusChart = this.renderPieChart(graphData.data.UsersByStatus)
    }

    return (
      <div className='dashboard-section-box full-length'>
        <div className='header-div'>
          <h4>Applicants</h4>
        </div>

        <div className='dashboard-total'>
          Total applicants: {dashboardStats.data.usersTotal}
        </div>
        <Row noGutters className='dashboard-chart-row'>
          {registrationChart}
          {usersByRoleChart}
        </Row>
        <Row noGutters className='dashboard-chart-row'>
          {usersByStatusChart}
        </Row>
      </div>
    )
  }

  renderSubmissionsSection = () => {
    const { graphData, dashboardStats } = this.state

    let submissionsByTypeChart
    let submissionsByStatusChart
    if (graphData.loaded) {
      submissionsByTypeChart = this.renderPieChart(graphData.data.SubmissionsByStatus)
      submissionsByStatusChart = this.renderPieChart(graphData.data.SubmissionsByType)
    }

    return (
      <div className='dashboard-section-box'>
        <div className='header-div'>
          <h4>Submissions</h4>
        </div>

        <div className='dashboard-total'>
          Total submissions: {dashboardStats.data.submissionsTotal}
        </div>
        <Row noGutters className='dashboard-chart-row'>
          {submissionsByTypeChart}
          {submissionsByStatusChart}
        </Row>
      </div>
    )
  }

  renderStorageSection = () => {
    const { graphData, dashboardStats } = this.state

    let dbStatsChart
    if (graphData.loaded) {
      dbStatsChart = this.renderPieChart(graphData.data.dbStats)
    }

    const roundedTotal = Math.round(dashboardStats.data.storageTotal * 100) / 100

    return (
      <div className='dashboard-section-box'>
        <div className='header-div'>
          <h4>System Stats</h4>
        </div>

        <div className='dashboard-total'>
          Total storage: {roundedTotal} MB
        </div>
        <Row noGutters className='dashboard-chart-row'>
          {dbStatsChart}
        </Row>
      </div>
    )
  }

  renderLineChart = data => {
    return (
      <div className='chart-container'>
        <div className='chart-header'>
          Weekly Registrations
        </div>
        <div className='chart-box'>
          <ResponsiveLine
            className='responsive-line-graph'
            data={this.handleChartData(data)}
            margin={{ top: 20, right: 20, bottom: 20, left: 50 }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            enablePoints={false}
            enableArea={true}
            enableGridX={false}
            enableGridY={false}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'count',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            colors={{ scheme: 'category10' }}
            tooltip={e => this.renderLineChartTooltip(e)}
            useMesh
          />
        </div>
      </div>
    )
  }

  renderLineChartTooltip = e => {
    const { x, y } = e.point.data

    return (
      <div className='line-chart-tooltip'>
        {x}: {y}
      </div>
    )
  }

  renderPieChart = data => {
    let title
    if (data.name == 'UsersByRole') title = 'Applicants By Role'
    else if (data.name == 'UsersByStatus') title = 'Applicants By Status'
    else if (data.name == 'SubmissionsByStatus') title = 'Submissions By Status'
    else if (data.name == 'SubmissionsByType') title = 'Submissions By Form Type'
    else if (data.name == 'dbStats') title = 'System Storage (in MB)'

    function sliceLabel(d) {
      return Math.round(d * 100) / 100
    }

    const legends = [{
      anchor: 'top-left',
      direction: 'column',
      translateY: 10,
      translateX: -182,
      itemWidth: 100,
      itemHeight: 8,
      itemTextColor: '#999',
      itemDirection: 'left-to-right',
      itemsSpacing: 10,
      symbolSize: 16,
      symbolShape: 'square',
      effects: [
          {
              on: 'hover',
              style: {
                  itemTextColor: '#000'
              }
          }
      ]
    }]

    return (
      <div className='chart-container'>
        <div className='chart-header'>
          {title}
        </div>
        <div className='chart-box'>
          <ResponsivePie
            className='pie-chart'
            data={data.dataArray}
            innerRadius={0.5}
            colors={{ scheme: 'category10' }}
            enableLabel={false}
            enableSlicesLabels={true}
            sortByValue={true}
            slicesLabelsTextColor='black'
            sliceLabel={d => sliceLabel(d.value)}
            enableRadialLabels={false}
            tooltip={e => this.renderPieChartTooltip(e)}
            legends={legends}
            margin={{left:180}}
          />
        </div>
      </div>
    )
  }

  renderPieChartTooltip = e => {
    const { label, value } = e
    const roundedValue = Math.round(value * 100) / 100

    return (
      <div className='pie-chart-tooltip'>
        {label}: {roundedValue}
      </div>
    )
  }

  renderLoading = () => (
    <Row noGutters className='app-spinner'>
      <Spinner animation='border' className='common-grey-spinner' />
    </Row>
  )

  renderContent = () => {
    let contents
    if (this.state.dashboardStats.loaded && this.state.graphData.loaded) {
      contents = (
        <React.Fragment>
          {this.renderApplicantsSection()}
          {this.renderSubmissionsSection()}
          {this.renderStorageSection()}
        </React.Fragment>
      )
    } else if (this.state.dashboardStats.loading || this.state.graphData.loading) {
      contents = this.renderLoading()
    }

    return contents
  }

  render() {

    return (
      <Container fluid className='dashboard-container'>
        <Row noGutters className='forms-header'>
          <h4>Dashboard</h4>
        </Row>

        {this.renderContent()}
      </Container>
    )
  }
}

export default Dashboard
