import React, { PureComponent } from 'react'
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  OverlayTrigger,
  Tooltip,
  Form,
} from 'react-bootstrap'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { NavLink } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../../images/FI_LOGO.png'

class Preregistration extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      applyingAs: '',
      individualUserAnswers: {
        answer1: '',
        answer2: '',
        answer3: ''
      },
      individualRequiredAnswers: {
        answer1: 1,
        answer2: 2,
        answer3: 1
      },
      entityUserAnswers: {
        answer1: '',
        answer2: '',
        answer3: ''
      },
      entityRequiredAnswers: {
        answer1: 1,
        answer2: 1,
        answer3: 2
      },
      captcha: false,
    }
  }

  handleAnswerStarterQuestion = e => {
    const { name } = e.target
    this.setState({
      applyingAs: name
    })
  }

  handleAnswerIndividualQuestions = (e, answer) => {
    const { name } = e.target
    this.setState({
      individualUserAnswers: {
        ...this.state.individualUserAnswers,
        [name]: answer
      }
    })
  }

  handleAnswerEntityQuestions = (e, answer) => {
    const { name } = e.target
    this.setState({
      entityUserAnswers: {
        ...this.state.entityUserAnswers,
        [name]: answer
      }
    })
  }

  onChangeCaptcha = value => {
    if (value) {
      this.setState({captcha: true})
    } else {
      this.setState({captcha: false})
    }
  }

  handleCheckIndividualAnswers = () => {
    const { answer1, answer2, answer3 } = this.state.individualUserAnswers
    if (answer1 === 'no' || answer2 === 'oneOrMore' || answer3 === 'yes') {
      // they failed
      this.props.submitPreregistration('fail')
    } else {
      // they continue to register
      this.props.submitPreregistration('pass')
    }
  }

  handleCheckEntityAnswers = () => {
    const { answer1, answer2, answer3 } = this.state.entityUserAnswers
    if (answer1 === 'no' || answer2 === 'oneOrMore' || answer3 === 'yes') {
      // they failed
      this.props.submitPreregistration('fail')
    } else {
      // they continue to register
      this.props.submitPreregistration('pass')
    }
  }

  /****************************************************************************/

  renderStarterQuestions = () => {
    const { applyingAs } = this.state

    const individualChecked = applyingAs === 'individual' ? true : false
    const entityChecked = applyingAs === 'entity' ? true : false

    return (
      <Form.Group className='prereg-form-group'>
        <label className='prereg-form-question'>
          Are you applying as an:
        </label>

        <Row noGutters className='prereg-row'>
          <Form.Check
            inline
            label='Individual'
            className='prereg-radio'
            onClick={this.handleAnswerStarterQuestion}
            name='individual'
            checked={individualChecked}
            type='radio' />
        </Row>

        <Row noGutters className='prereg-row'>
          <Form.Check
            inline
            label='Entity (Body Corporate, General Partnership, Sovereign Wealth Fund, Pension Fund, Trust, Foundation)'
            className='prereg-radio'
            onClick={this.handleAnswerStarterQuestion}
            name='entity'
            checked={entityChecked}
            type='radio' />
        </Row>
      </Form.Group>

    )
  }

  renderIndividualQuestions = () => {
    const { answer1, answer2, answer3 } = this.state.individualUserAnswers

    return (
      <React.Fragment>

        <Form.Group className='prereg-form-group'>
          <label className='prereg-form-question'>
            1. Are you above 18 years of age?
          </label>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='Yes'
              className='prereg-radio'
              onClick={e => this.handleAnswerIndividualQuestions(e, 'yes')}
              name='answer1'
              checked={answer1 === 'yes' ? true : false}
              type='radio' />
          </Row>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='No'
              className='prereg-radio'
              onClick={e => this.handleAnswerIndividualQuestions(e, 'no')}
              name='answer1'
              checked={answer1 === 'no' ? true : false}
              type='radio' />
          </Row>
        </Form.Group>

        <Form.Group className='prereg-form-group'>
          <label className='prereg-form-question'>
            2. Please confirm how the following statements apply to your own personal circumstances:
            <ul className='prereg-form-list'>
              <li>
                 I have been convicted or found guilty by any court of competent jurisdiction in respect of any offence, other than a minor road traffic offence.
              </li>
              <li>
                I have been the subject of disciplinary sanctions by a government body, government agency, self-regulatory organisation or other professional body.
              </li>
              <li>
                I have been concerned with the management of a Body Corporate which has been or is currently the subject of an investigation into an allegation of misconduct or malpractice.
              </li>
              <li>
                I have been the subject of an adverse finding in a civil proceeding by any court of competent jurisdiction for any fraud, misfeasance, or other misconduct, whether in connection with the formation or management of a corporation or otherwise.
              </li>
              <li>
                I have been the subject an adverse finding or an agreed settlement in a civil action by any court or tribunal of competent jurisdiction resulting in an award against the candidate in excess of $10,000 or awards that total more than $10,000.
              </li>
              <li>
                I have been the subject of an order of disqualification as a director or to act in the management or conduct of the affairs of a corporation by a court of competent jurisdiction or regulator.
              </li>
            </ul>
            Choose one of the following:
          </label>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='I confirm that NONE of the above statements apply to my own personal circumstances'
              className='prereg-radio'
              onClick={e => this.handleAnswerIndividualQuestions(e, 'none')}
              name='answer2'
              checked={answer2 === 'none' ? true : false}
              type='radio' />
          </Row>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='I confirm that ONE or MORE of the above statements apply to my own personal circumstances'
              className='prereg-radio'
              onClick={e => this.handleAnswerIndividualQuestions(e, 'oneOrMore')}
              name='answer2'
              checked={answer2 === 'oneOrMore' ? true : false}
              type='radio' />
          </Row>
        </Form.Group>

        <Form.Group className='prereg-form-group'>
          <label className='prereg-form-question'>
            3. Have you been declared bankrupt or made subject to any similar insolvency proceeding which has not been discharged?
          </label>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='Yes'
              className='prereg-radio'
              onClick={e => this.handleAnswerIndividualQuestions(e, 'yes')}
              name='answer3'
              checked={answer3 === 'yes' ? true : false}
              type='radio' />
          </Row>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='No'
              className='prereg-radio'
              onClick={e => this.handleAnswerIndividualQuestions(e, 'no')}
              name='answer3'
              checked={answer3 === 'no' ? true : false}
              type='radio' />
          </Row>
        </Form.Group>

      </React.Fragment>
    )
  }

  renderEntityQuestions = () => {
    const { answer1, answer2, answer3 } = this.state.entityUserAnswers

    return (
      <React.Fragment>

        <Form.Group className='prereg-form-group'>
          <label className='prereg-form-question'>
            1. Are you authorized to act on behalf of the applicant in a formal capacity (e.g. by Board resolution)?
          </label>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='Yes'
              className='prereg-radio'
              onClick={e => this.handleAnswerEntityQuestions(e, 'yes')}
              name='answer1'
              checked={answer1 === 'yes' ? true : false}
              type='radio' />
          </Row>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='No'
              className='prereg-radio'
              onClick={e => this.handleAnswerEntityQuestions(e, 'no')}
              name='answer1'
              checked={answer1 === 'no' ? true : false}
              type='radio' />
          </Row>
        </Form.Group>

        <Form.Group className='prereg-form-group'>
          <label className='prereg-form-question'>
            2. Please confirm how the following statements apply to the entity you are acting on behalf of:
            <ul className='prereg-form-list'>
              <li>
                 The applicant or any member of its group has been made aware, whether formally or informally, that it is the subject of a current or pending investigation, review or disciplinary procedure by any regulatory authority, professional body, Financial Services Regulator, self-regulatory organisation, regulated exchange, clearing house, government body, agency, or any other officially appointed inquiry.
              </li>
              <li>
                The applicant or any member of its group has been convicted (including where a conviction has been completed, removed from record, or otherwise spent, as the case may be) or found guilty by any court of a competent jurisdiction of any criminal offence.
              </li>
              <li>
                The applicant or any member of its group in the last ten years has been the subject of disciplinary procedures by a government body or agency or any Financial Services Regulator, self-regulatory organisation, or other professional body?
              </li>
              <li>
                The applicant or any member of its group in the last ten years has contravened any provision of financial services legislation or of rules, regulations, statements of principle, or codes of practice made under it or made by a self-regulatory organisation, Financial Services Regulator, regulated exchange, or clearing house.
              </li>
              <li>
                The applicant or any member of its group in the last ten years has been refused or had a restriction placed on the right to carry on a trade, business, or profession requiring a licence, registration, or other permission.
              </li>
              <li>
                The applicant or any member of its group in the last ten years has received an adverse finding or an agreed settlement in a civil action by any court or tribunal of competent jurisdiction.
              </li>
              <li>
                The applicant or any member of its group in the last ten years has been censured, disciplined, publicly criticised, or the subject of any investigation or enquiry by any regulatory authority, Financial Services Regulator, or officially appointed inquiry.
              </li>
            </ul>
            Choose one of the following:
          </label>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='I confirm that NONE of the above statements apply to my own personal circumstances'
              className='prereg-radio'
              onClick={e => this.handleAnswerEntityQuestions(e, 'none')}
              name='answer2'
              checked={answer2 === 'none' ? true : false}
              type='radio' />
          </Row>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='I confirm that ONE or MORE of the above statements apply to my own personal circumstances'
              className='prereg-radio'
              onClick={e => this.handleAnswerEntityQuestions(e, 'oneOrMore')}
              name='answer2'
              checked={answer2 === 'oneOrMore' ? true : false}
              type='radio' />
          </Row>
        </Form.Group>

        <Form.Group className='prereg-form-group'>
          <label className='prereg-form-question'>
            3. Has the applicant ever been declared insolvent or made subject to any insolvency proceedings which have not been discharged?
          </label>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='Yes'
              className='prereg-radio'
              onClick={e => this.handleAnswerEntityQuestions(e, 'yes')}
              name='answer3'
              checked={answer3 === 'yes' ? true : false}
              type='radio' />
          </Row>

          <Row noGutters className='prereg-row'>
            <Form.Check
              inline
              label='No'
              className='prereg-radio'
              onClick={e => this.handleAnswerEntityQuestions(e, 'no')}
              name='answer3'
              checked={answer3 === 'no' ? true : false}
              type='radio' />
          </Row>
        </Form.Group>

      </React.Fragment>
    )
  }

  renderCaptcha = () => {
    const { siteKey } = this.props

    return (
      <ReCAPTCHA
        className='recaptcha'
        sitekey={siteKey}
        onChange={this.onChangeCaptcha}
      />
    )
  }

  renderNextButton = () => {
    const { applyingAs, captcha } = this.state

    let disabled = true
    let onClick
    if (applyingAs === 'individual') {
      onClick = this.handleCheckIndividualAnswers
      const { answer1, answer2, answer3 } = this.state.individualUserAnswers
      if (answer1 && answer2 && answer3 && captcha) {
        disabled = false
      }
    } else if (applyingAs === 'entity') {
      onClick = this.handleCheckEntityAnswers
      const { answer1, answer2, answer3 } = this.state.entityUserAnswers
      if (answer1 && answer2 && answer3 && captcha) {
        disabled = false
      }
    }

    return (
      <Button className='teal-button' disabled={disabled} onClick={onClick}>
        Next
      </Button>
    )
  }

  renderLogo = () => (
    <div className='login-logo-row'>
      <div className='login-logo-box'>
        <img src={logo} className='login-logo' />
      </div>
    </div>
  )

  render() {
    const { applyingAs } = this.state

    let questions
    let captcha
    let nextButton
    if (applyingAs === 'individual') {
      questions = this.renderIndividualQuestions()
      captcha = this.renderCaptcha()
      nextButton = this.renderNextButton()
    } else if (applyingAs === 'entity') {
      questions = this.renderEntityQuestions()
      captcha = this.renderCaptcha()
      nextButton = this.renderNextButton()
    } else {
      questions = ''
      captcha = ''
    }

    return (
      <Container fluid className='preregistration-container'>
        {this.renderLogo()}
        <Row noGutters>
          <Col lg={1} />
          <Col>
            <div className='preregistration-starter-box'>
              <Row noGutters className='preregistration-starter-box-row'>

                <Col className='preregistration-content-col'>

                  <Row noGutters className='prereg-header'>
                    <h4>Preregistration</h4>
                  </Row>

                  <Form className='preregistration-form'>
                    {this.renderStarterQuestions()}
                  </Form>

                  <Form className='preregistration-form bottom-form'>
                    {questions}
                  </Form>

                  <Row noGutters className='prereg-captcha-row'>
                    {captcha}
                  </Row>

                  <Row noGutters className='prereg-next-button-row'>
                    {nextButton}
                  </Row>

                  <div className='prereg-navlink'>
                    Already have an account?
                    <NavLink to='/login'>Log in</NavLink>
                  </div>

                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={1} />
        </Row>
      </Container>
    )
  }
}


export default Preregistration
