import React, { PureComponent } from "react";
import {
	Nav,
	Row,
	Col,
	Button,
	FormControl,
	Container,
	Card,
	Image,
	OverlayTrigger,
	Tooltip
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo_fiui_full.png";
import { AiFillEye, AiFillEyeInvisible, AiOutlineQuestionCircle } from "react-icons/ai";

class Reset extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showPassword: true,
			showConfirmPassword: true,
			passwordError: false,
			confirmPasswordError: false,
			password: "",
			confirmPassword: "",
			token: "",
			email: "",
			noTokenRecognized: false,
			passwordSuccessfullyReset: this.props.passwordSuccessfullyReset,
		};
	}

	componentDidMount() {
		this.handleGetTokenAndEmailFromUrl();
		this.props.resetReset();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				passwordSuccessfullyReset: nextProps.passwordSuccessfullyReset,
			});
		}
	}

	handleGetTokenAndEmailFromUrl = () => {
		const { search } = this.props.history.location;

		if (search) {
			const splitUrl = search.split("&");
			const token = splitUrl[0].split("=")[1];
			const email = splitUrl[1].split("=")[1];

			this.setState({
				token: token,
				email: email,
			});
		} else {
			this.props.history.push("/login");
		}
	};

	handleOnChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleReset = () => {
		const { password, token, email } = this.state;
		const body = { password, token, email };

		this.props.resetRequest(body);
	};

	togglePsVisibility = (confirm = "") => {
		const passwordBox = document.getElementsByName(
			confirm === "confirm" ? "confirmPassword" : "password"
		);

		passwordBox.forEach(
			(psw) => (psw.type = psw.type === "text" ? "password" : "text")
		);

		const prop =
			confirm === "confirm" ? "showConfirmPassword" : "showPassword";

		this.setState({
			...this.state,
			[prop]: passwordBox[0].type === "password",
		});
	};


	checkPasswordFormat = () => {
		// password must be at least 8 characters long and contain at least 1 number and 1 letter
		let passwordError = false;
		if (
			!/\d/.test(this.state.password) ||
			!/[a-zA-Z]/.test(this.state.password) ||
			this.state.password.length < 8
		) {
			passwordError = true;
		}

		this.setState({
			...this.state,
			passwordError: passwordError,
		});
	};

	confirmPassword = () => {
		let confirmPasswordError = false;

		if (this.state.password !== this.state.confirmPassword)
			confirmPasswordError = true;

		this.setState({
			...this.state,
			confirmPasswordError,
		});
	};

	renderPasswordFormatIcon = () => {
		return (
			<OverlayTrigger
				key="role-description"
				placement="top"
				overlay={
					<Tooltip>
						Your password must be a minimum of eight characters long
						and contain at least one letter and one number.
					</Tooltip>
				}
			>
				<AiOutlineQuestionCircle className="individual-form-icon password-format-icon" />
			</OverlayTrigger>
		);
	};

	renderReset = () => {
		const { password, confirmPassword, passwordError, confirmPasswordError } = this.state;
		const disabled =
			(password && confirmPassword && password === confirmPassword
				? false
				: true) ||
			passwordError || confirmPasswordError;

		// Disable double click selection on password icons.
		const elem = document.getElementsByClassName("password-icon");

		Array.from(elem).forEach((el) => {
			el.addEventListener("mousedown", (e) => {
				if (e.detail > 1) {
					e.preventDefault();
				}
			});
		});

		return (
			<Row noGutters className="login-row">
				<Col sm={8} md={6} lg={4} xl={4}>
					<Card className="login-box">
						<Row noGutters>
							<Col className="login-content-col">
								<Row noGutters className="login-header">
									<Image
										fluid
										src={logo}
										className="login-logo"
									/>
								</Row>

								<Row
									noGutters
									className="login-box-padding login-formcontrol-row"
								>
									<Col className="login-box-col">
										<Row
											noGutters
											className="login-welcome"
										>
											<h5>Please enter a new password</h5>
										</Row>

										<Row
											noGutters
											className="login-formcontrol-container"
										>
											<div className="login-formcontrol-box" id={
												this.state.passwordError &&
													"formControl-error"
												}
											>
												<div className="login-formcontrol-text">
													Password
												</div>
												<FormControl
													size="sm"
													name="password"
													type="password"
													value={password}
													onChange={
														this.handleOnChange
													}
													onBlur={
														this
															.checkPasswordFormat
													}
													className="login-formcontrol"
												/>
												<div className="password-icon">
													{!this.state
														.showPassword ? (
														<AiFillEyeInvisible
															onClick={
																this
																	.togglePsVisibility
															}
														/>
													) : (
														<AiFillEye
															onClick={
																this
																	.togglePsVisibility
															}
														/>
													)}
												</div>
												{this.state
													.passwordError && (
														<div className="system-form-control-error-text">
															Your password must
															be a minimum of
															eight characters
															long and contain at
															least one letter and
															one number.
														</div>
												)}
												{this.renderPasswordFormatIcon()}
											</div>
										</Row>

										<Row
											noGutters
											className="login-formcontrol-container"
										>
											<div className="login-formcontrol-box"
												id={
													this.state
														.confirmPasswordError &&
													"formControl-error"
												}
											>
												<div className="login-formcontrol-text">
													Confirm Password
												</div>
												<FormControl
													size="sm"
													name="confirmPassword"
													type="password"
													value={confirmPassword}
													onChange={
														this.handleOnChange
													}
													onBlur={
														this.confirmPassword
													}
													className="login-formcontrol"
												/>
												<div className="password-icon">
													{!this.state
														.showConfirmPassword ? (
														<AiFillEyeInvisible
															onClick={() =>
																this.togglePsVisibility(
																	"confirm"
																)
															}
														/>
													) : (
														<AiFillEye
															onClick={() =>
																this.togglePsVisibility(
																	"confirm"
																)
															}
														/>
													)}
												</div>
												{this.state
													.confirmPasswordError && (
														<div className="system-form-control-error-text">
															Passwords must
															match!
														</div>
												)}
											</div>
										</Row>

										<Row
											noGutters
											className="login-button-row"
										>
											<Button
												size="sm"
												disabled={disabled}
												onClick={this.handleReset}
												className="teal-button"
											>
												RESET
											</Button>
										</Row>

										<div className="login-navlinks">
											<NavLink to="/login">
												Log in
											</NavLink>
											<NavLink to="/register">
												Create an Account
											</NavLink>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		);
	};

	renderPasswordSuccessfullyReset = () => {
		return (
			<Row noGutters className="login-row">
				<Col sm={8} md={6} lg={4} xl={4}>
					<Card className="login-box">
						<Row noGutters>
							<Col className="login-content-col">
								<Row noGutters className="login-header">
									<Image
										fluid
										src={logo}
										className="login-logo"
									/>
								</Row>

								<Row
									noGutters
									className="login-box-padding login-formcontrol-row"
								>
									<Col className="login-box-col">
										<Row
											noGutters
											className="login-welcome"
										>
											<h5>
												Your password has been
												successfully reset.
											</h5>
										</Row>

										<div className="login-navlinks">
											<NavLink to="/login">
												Log in
											</NavLink>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		);
	};

	render() {
		let content;
		if (this.state.passwordSuccessfullyReset) {
			content = this.renderPasswordSuccessfullyReset();
		} else {
			content = this.renderReset();
		}
		return (
			<Container fluid className="login-container">
				{content}
			</Container>
		);
	}
}

export default Reset;
