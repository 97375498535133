import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Container,
  Card,
  Image
} from 'react-bootstrap'
import { NavLink } from "react-router-dom"
import logo from '../../images/logo_fiui_full.png'

class SessionExpired extends PureComponent {
  constructor(props) {
    super(props)
  }

  renderLogo = () => (
    <div className='login-logo-row'>
      <div className='login-logo-box'>
        <img src={logo} className='login-logo' />
      </div>
    </div>
  )

  render() {
    return (
      <Container fluid className='login-container'>
        <Row noGutters className='login-row'>
          <Col sm={8} md={6} lg={4} xl={4} className='login-col'>
            <Card className='login-box'>
              <Row noGutters>
                <Col className='login-content-col'>

                  <Row noGutters className='login-header'>
                    <Image fluid src={logo} className='login-logo' />
                  </Row>

                  <Row noGutters className='login-box-padding login-formcontrol-row'>
                    <Col className='login-box-col'>
                      <Row noGutters className='login-welcome'>
                        <p>Your session has expired and you have been logged out.</p>
                      </Row>

                      <div className='login-navlinks'>
                        <NavLink to='/login'>Log in</NavLink>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default SessionExpired
