import { connect } from "react-redux"
import Register from './Register'
import {
  registerRequest,
  getRoleListRequest,
  deleteAccountRequest
} from '../../redux/actions/register'

const mapStateToProps = state => ({
  roleList: state.register.roleList,
  account: state.auth.account,
  preregistration: state.register.preregistration
})

const mapDispatchToProps = {
  registerRequest,
  getRoleListRequest,
  deleteAccountRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
