import { put, takeLatest, call, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { history } from '../../../configureStore';
import request from '../../../apiRequest';
import * as ACTIONS from '../../actions/login'
import * as CONSTANTS from '../../constants/login'
import * as UTIL_ACTIONS from '../../actions/utilities'

function* login(action) {
  try {
    const { email, password } = action.data
    const body = { email, password }

    const responseData = yield call(
      request,
      '/account/login',
      'POST',
      body,
    )

    //yield put(UTIL_ACTIONS.initializeWebsocketConnection(responseData.data))

    if (responseData.data.role === 'admin') {
      history.push('/admin')
    } else {
      history.push('/')
    }

    window.location.href = window.location.href

    console.log(responseData)

    // This will expire the session after a certain amount of time
    if (responseData.data.session_expires) {
      yield delay(responseData.data.session_expires)
      yield put(ACTIONS.logoutRequest('session_expired'))
    }
  } catch(err) {
    toast.error(err.reason)
  }
}

function* logout(action) {
  try {
    const responseData = yield call(
      request,
      '/account/logout',
      'POST'
    )

    if (action.data == 'session_expired') {
      window.location.pathname = '/session-expired'
    } else {
      window.location.href = '/login'
    }

  } catch (err) {
    toast.error(err.reason)
  }
}

export default function* loginSaga() {
  yield takeLatest(CONSTANTS.LOGIN_REQUEST, login)
  yield takeLatest(CONSTANTS.LOGOUT_REQUEST, logout)
}
