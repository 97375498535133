export let baseUrl = "";

if (process.env.REACT_APP_BACKEND) {
  baseUrl = process.env.REACT_APP_BACKEND;
} else if (process.env.NODE_ENV == "development") {
  baseUrl = `https://onboarding.fi-edition.com/api`;
  // baseUrl = `https://mid-onboarding.fi-edition.com/api`
} else {
  baseUrl = `https://onboarding.fi-edition.com/api`;
  // baseUrl = `https://mid-onboarding.fi-edition.com/api`
}
