import React, { PureComponent } from "react";
import {
	Nav,
	Row,
	Col,
	Button,
	FormControl,
	Container,
	Spinner,
	Image,
	ListGroup,
	NavDropdown,
	Accordion,
	Dropdown,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
	AiOutlineDashboard,
	AiOutlineAudit,
	AiOutlineSolution,
	AiOutlineUsergroupAdd,
	AiOutlineCheckSquare,
	AiOutlineSearch,
	AiOutlineUser,
	AiOutlineLogout,
	AiOutlineCaretRight,
	AiOutlineCaretDown,
} from "react-icons/ai";
import {
	FiSearch,
	FiChevronDown,
	FiChevronRight,
	FiChevronLeft,
} from "react-icons/fi";

import * as COMPONENT from "./";
import { Switch, Route } from "react-router-dom";

import fiLogo from "../../images/FI_LOGO.png";

class Admin extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			collapseSidebar: false,
			currentSection: "dashboard",
			account: this.props.account,
			navbarLinks: this.props.navbarLinks,
		};
	}

	componentDidMount() {
		this.handleFixPath();
		this.props.getNavbarLinksRequest();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				account: nextProps.account,
				navbarLinks: nextProps.navbarLinks,
			});
		}
	}

	handleFixPath = () => {
		if (this.props.location.pathname == "/") {
			this.props.history.push("/admin");
		}
	};

	handleGetSectionFromUrl = () => {
		const { pathname } = this.props.history.location;

		const section = pathname.split("/admin/");

		if (section[1]) {
			// if a section is in the url, select that section
			this.handleSelectSection(section[1]);
		} else {
			// otherwise, set the current section to dashboard
			this.setState({ currentSection: "dashboard" });
		}
	};

	handleSelectSection = (section) => {
		this.setState({ currentSection: section });
	};

	handleLogout = () => {
		this.props.logoutRequest();
	};

	/***************************************************************************/

	renderSidebar = () => {
		const { collapseSidebar, currentSection } = this.state;

		let sidebarToggle;
		if (collapseSidebar) {
			sidebarToggle = "collapsed-sidebar";
		}

		return (
			<div className="admin-sidebar" id={sidebarToggle}>
				<Col>
					<Row noGutters className="admin-logo-row">
						<NavLink to="/admin">
							<Image className="admin-logo" src={fiLogo} />
						</NavLink>
					</Row>

					<Row noGutters className="admin-system-row">
						<div className="system-header">SYSTEM</div>
					</Row>

					<ListGroup variant="flush" className="admin-list-group">
						<ListGroup.Item
							className="admin-list-item"
							active={currentSection === "dashboard"}
						>
							<NavLink
								to="/admin/"
								className="admin-list-item-title"
							>
								<Row noGutters>
									<AiOutlineDashboard className="admin-list-item-icon" />
									Dashboard
								</Row>
							</NavLink>
						</ListGroup.Item>

						<ListGroup.Item
							className="admin-list-item"
							active={currentSection === "forms"}
						>
							<NavLink
								to="/admin/forms"
								className="admin-list-item-title"
							>
								<Row noGutters>
									<AiOutlineAudit className="admin-list-item-icon" />
									Create Forms
								</Row>
							</NavLink>
						</ListGroup.Item>

						<ListGroup.Item
							className="admin-list-item"
							active={currentSection === "roles"}
						>
							<NavLink
								to="/admin/roles"
								className="admin-list-item-title"
							>
								<Row noGutters>
									<AiOutlineSolution className="admin-list-item-icon" />
									Define Roles
								</Row>
							</NavLink>
						</ListGroup.Item>

						<ListGroup.Item
							className="admin-list-item"
							active={currentSection === "users"}
						>
							{this.renderManageUsersListItem()}
						</ListGroup.Item>

						<ListGroup.Item
							className="admin-list-item"
							active={currentSection === "submissions"}
						>
							<NavLink
								to="/admin/submissions"
								className="admin-list-item-title"
							>
								<Row noGutters>
									<AiOutlineCheckSquare className="admin-list-item-icon" />
									Review Submissions
								</Row>
							</NavLink>
						</ListGroup.Item>
					</ListGroup>

					{this.renderSidebarToggleButton()}
				</Col>
			</div>
		);
	};

	handleToggleCollapseSidebar = () => {
		this.setState({
			collapseSidebar: !this.state.collapseSidebar,
		});
	};

	renderManageUsersListItem = () => {
		const { currentSection } = this.state;

		return (
			<Dropdown className="admin-list-accordion">
				<Dropdown.Toggle>
					<Row noGutters className="admin-list-accordion-title">
						<AiOutlineUsergroupAdd className="admin-list-item-icon" />
						Manage Users
					</Row>
				</Dropdown.Toggle>

				<Dropdown.Menu className="admin-list-accordion-collapse classify-onboarding">
					<ul>
						<NavLink
							to="/admin/manage-users"
							className="admin-list-item-title"
						>
							<li>Applicants</li>
						</NavLink>

						<NavLink
							to="/admin/manage-administrators"
							className="admin-list-item-title"
						>
							<li>Administrators</li>
						</NavLink>
					</ul>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	renderNavbarLink = (link) => {
		return (
			<NavDropdown.Item
				href={link.url}
				className="admin-topbar-navdropitem"
			>
				{link.name}
			</NavDropdown.Item>
		);
	};

	renderTopbar = () => {
		const { account, navbarLinks } = this.state;

		let displayName;
		if (account.loaded) {
			if (account.data.first_name && account.data.last_name) {
				displayName = `${account.data.first_name} ${account.data.last_name}`;
			} else {
				displayName = "My Account";
			}
		}

		let displayNavbarLinks;
		if (navbarLinks) {
			if (navbarLinks.loaded) {
				displayNavbarLinks = (
					<NavDropdown
						className="admin-topbar-navdropdown"
						title="Onboarding"
					>
						{navbarLinks.data.map((link) =>
							this.renderNavbarLink(link)
						)}
					</NavDropdown>
				);
			}
		}

		const title = (
			<React.Fragment>
				{displayName}
				<AiOutlineUser />
			</React.Fragment>
		);

		return (
			<div className="admin-topbar">
				<Row noGutters className="admin-topbar-row">
					<div className="admin-search-container">
						{displayNavbarLinks}
					</div>

					<NavDropdown className="admin-topbar-nav" title={title}>
						<NavDropdown.Item
							className="admin-topbar-login"
							onClick={this.handleLogout}
						>
							<AiOutlineLogout />
							Log out
						</NavDropdown.Item>
					</NavDropdown>
				</Row>
			</div>
		);
	};

	renderSidebarToggleButton = () => {
		const { collapseSidebar } = this.state;

		let icon;
		if (collapseSidebar) {
			icon = (
				<FiChevronRight className="admin-sidebar-collapse-toggle-button" />
			);
		} else {
			icon = (
				<FiChevronLeft className="admin-sidebar-collapse-toggle-button" />
			);
		}
		return (
			<Row noGutters className="admin-sidebar-collapse-toggle-row">
				<div
					onClick={this.handleToggleCollapseSidebar}
					className="admin-sidebar-collapse-toggle-aura"
				>
					{icon}
				</div>
			</Row>
		);
	};

	render() {
		return (
			<Container fluid className="admin-container">
				<Row noGutters style={{ flexWrap: "nowrap" }}>
					{this.renderSidebar()}

					<Col>
						{this.renderTopbar()}

						<div className="admin-content-pane">
							<Route
								exact
								path="/admin"
								component={COMPONENT.Dashboard}
							/>
							<Route
								exact
								path="/admin/forms"
								component={COMPONENT.Forms}
							/>
							<Route
								exact
								path="/admin/roles"
								component={COMPONENT.Roles}
							/>
							<Route
								exact
								path="/admin/submissions"
								component={COMPONENT.Submissions}
							/>
							<Route
								exact
								path="/admin/manage-users"
								component={COMPONENT.UserManagement}
							/>
							<Route
								exact
								path="/admin/manage-administrators"
								component={COMPONENT.AdminManagement}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Admin;
