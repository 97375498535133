import React, { PureComponent } from "react";
import {
	Nav,
	Row,
	Col,
	Button,
	FormControl,
	Container,
	ListGroup,
	// Image,
	Spinner,
	ProgressBar,
	FormFile,
	Form,
	NavDropdown,
	Table,
	FormCheck,
} from "react-bootstrap";
import DatePicker from "react-date-picker";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
	AiFillCheckCircle,
	AiFillExclamationCircle,
	AiOutlineLogout,
	AiOutlineClose,
	AiOutlineUser,
} from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import countryList from "react-select-country-list";
import SignatureCanvas from "react-signature-canvas";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";

import fiLogo from "../../images/FI_LOGO.png";

class Registration extends PureComponent {
	constructor(props) {
		super(props);

		this.locationOptions = countryList().getLabels();

		this.state = {
			currentForm: "confirm",
			checkList: {
				data: [],
				loaded: false,
				loading: false,
				error: "",
			},
			account: {
				data: {},
				loading: false,
				loaded: false,
				error: "",
			},
			specificUserForm: {
				data: {},
				loaded: false,
				loading: false,
				error: "",
				saving: false,
				validity: true,
				validated: false,
			},
			userCompletedAllForms: false,
		};
	}

	componentDidMount() {
		this.props.getCheckListRequest(this.props.account.data);
		this.handleCheckUrlForToken();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				checkList: nextProps.checkList,
				account: nextProps.account,
				specificUserForm: nextProps.specificUserForm,
				currentForm: nextProps.currentForm,
			});
		}
	}

	handleCheckValidation = () => {
		const { fields } = this.state.specificUserForm.data;
		let validation = true;

		for (let i = 0; i < fields.length; i++) {
			if (fields[i].required && !fields[i].value) {
				validation = false;
			}
		}

		this.setState({ validated: true });

		return validation;
	};

	handleCheckUrlForToken = () => {
		const { search } = this.props.history.location;

		if (search) {
			const searchBreakdown = search.split("=");
			const tokenParameter = searchBreakdown[0];
			const token = searchBreakdown[1];

			if (tokenParameter === "?token" && token) {
				this.props.confirmEmailRequest(token);
			}
		}
	};

	handleSelectSection = (section, id) => {
		this.setState({
			currentForm: section,
		});

		if (id) {
			this.props.getSpecificUserFormRequest(id);
		}
	};

	handleSelectNextSection = (currentSection, currentSectionId) => {
		const { data } = this.state.checkList;

		for (let i = 0; i < data.length; i++) {
			if (data[i]._id == currentSectionId && i !== data.length - 1) {
				const nextSection = data[i + 1].name;
				const nextId = data[i + 1]._id;
				this.handleSelectSection(nextSection, nextId);
			} else if (
				data[i]._id == currentSectionId &&
				i == data.length - 1
			) {
				this.handleSelectSection("confirm");
			}
		}
	};

	handleResendConfirmationEmail = () =>
		this.props.resendConfirmationEmailRequest();

	handleOnChangeFormField = (
		e,
		index,
		subfieldDataIndex,
		subfieldIndex,
		fieldType
	) => {
		const newFormFieldsData = this.state.specificUserForm.data.fields;

		if (subfieldDataIndex >= 0) {
			newFormFieldsData[index].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = e.target.value;
		} else {
			newFormFieldsData[index].value = e.target.value;
		}

		this.setState(
			{
				...this.state,
				specificUserForm: {
					...this.state.specificUserForm,
				},
			},
			() => this.handleEditForm()
		);
	};

	handleOnChangeLocation = (e, index, subfieldDataIndex, subfieldIndex) => {
		const value = e[0];

		const newFormFieldsData = this.state.specificUserForm.data.fields;

		if (subfieldDataIndex >= 0) {
			newFormFieldsData[index].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = value;
		} else {
			newFormFieldsData[index].value = value;
		}

		this.setState(
			{
				...this.state,
				specificUserForm: {
					...this.state.specificUserForm,
				},
			},
			() => this.handleEditForm()
		);
	};

	handleOnChangeDateField = (e, index, subfieldDataIndex, subfieldIndex) => {
		const value = e;
		const newFormFieldsData = this.state.specificUserForm.data.fields;

		if (subfieldDataIndex >= 0) {
			newFormFieldsData[index].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = value;
		} else {
			newFormFieldsData[index].value = value;
		}

		this.setState(
			{
				...this.state,
				specificUserForm: {
					...this.state.specificUserForm,
				},
			},
			() => this.handleEditForm()
		);
	};

	handleEditForm = () => {
		const { data } = this.state.specificUserForm;
		this.props.editSpecificUserFormRequest(data);
	};

	handleSubmitForm = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		const validity = form.checkValidity();
		const { checkList } = this.state;

		for (
			let i = 0;
			i < this.state.specificUserForm.data.fields.length;
			i++
		) {
			const elem = this.state.specificUserForm.data.fields[i];

			if (elem.required && elem.fieldType === "checkbox") {
				if (elem.subfields.length === 0) {
					// Normal checkbox with no nested elements.
					return toast.error(`${elem.fieldName} is required.`);
				} else if (elem.subfields.length > 2) {
					// Greater than 2 indicates nested checkboxes
					for (let j = 0; j < elem.subfields.length; j++) {
						const nestedElem = elem.subfields[j];
						if (
							!nestedElem?.value ||
							nestedElem.value.length === 0
						) {
							return toast.error(
								`${nestedElem.fieldName} is required.`
							);
						}
					}
				}
			} else if (elem.required && elem.value.length === 0) {
				// Non checkbox
				return toast.error(`${elem.fieldName} is required.`);
			}
		}
		if (validity) {
			this.setState({
				specificUserForm: {
					...this.state.specificUserForm,
					validity: true,
				},
			});
			const { data } = this.state.specificUserForm;
			this.props.submitSpecificUserFormRequest(
				data._id,
				this.state.account.data
			);
			this.handleSelectNextSection(data.name, data._id);
		} else {
			this.setState({
				specificUserForm: {
					...this.state.specificUserForm,
					validity: false,
				},
			});
		}
	};

	handleSelectFile = (e, fieldIndex, subfieldDataIndex, subfieldIndex) => {
		if (e.target.files.length > 0) {
			const formData = this.state.specificUserForm.data;
			var fileData = e.target.files[0];
			this.props.registrationUploadFileRequest(
				fileData,
				formData,
				fieldIndex,
				subfieldDataIndex,
				subfieldIndex
			);
		}
	};

	handleClearFile = (url, fieldIndex, subfieldDataIndex, subfieldIndex) => {
		const data = this.state.specificUserForm.data;

		this.setState(
			{
				...this.state,
				specificUserForm: {
					...this.state.specificUserForm,
				},
			},
			() =>
				this.props.deleteUploadedFileRequest(
					url,
					fieldIndex,
					data,
					subfieldDataIndex,
					subfieldIndex
				)
		);
	};

	handleClickDocumentPreview = (url) => {
		let pdfWindow = window.open("");
		pdfWindow.document.write(
			`<object width='100%' height='100%' data={${url}} type='application/pdf'>
        <embed src={${url}} type='application/pdf' />
      </object>`
		);

		return pdfWindow;
	};

	handleClearSig = (index, subfieldDataIndex, subfieldIndex) => {
		this.sigPad.clear();

		const newFormFieldsData = this.state.specificUserForm.data.fields;
		if (subfieldDataIndex >= 0) {
			newFormFieldsData[index].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = "";
		} else {
			newFormFieldsData[index].value = "";
		}

		this.setState(
			{
				...this.state,
				specificUserForm: {
					...this.state.specificUserForm,
				},
			},
			() => this.handleEditForm()
		);
	};

	handleSignForm = (index, subfieldDataIndex, subfieldIndex) => {
		const value = this.sigPad.getCanvas().toDataURL("image/png");
		const newFormFieldsData = this.state.specificUserForm.data.fields;

		if (subfieldDataIndex >= 0) {
			newFormFieldsData[index].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = value;
		} else {
			newFormFieldsData[index].value = value;
		}

		this.setState(
			{
				...this.state,
				specificUserForm: {
					...this.state.specificUserForm,
				},
			},
			() => this.handleEditForm()
		);
	};

	handleDetermineProgressBarProgress = () => {
		const { checkList, account } = this.state;

		let total = 0; // should be 0
		let numberOfItems = 1; // to account for the confirmEmail form
		if (account.loaded) {
			if (account.data.emailConfirmed) {
				total += 1;
			}
		}

		if (checkList.loaded) {
			numberOfItems += checkList.data.length;

			for (let i = 0; i < checkList.data.length; i++) {
				if (checkList.data[i].status !== "pending") {
					total += 1;
				}
			}
		}

		const progress = Math.round((total / numberOfItems) * 100);

		if (progress == 100) {
			this.setState({
				userCompletedAllForms: true,
			});
		} else {
			this.setState({
				userCompletedAllForms: false,
			});
		}

		return progress;
	};

	handleLogout = () => {
		this.props.logoutRequest();
	};

	handleChangeCheckboxSubfieldValue = (option, subfieldIndex, fieldIndex) => {
		const newFormFieldsData = this.state.specificUserForm.data.fields;
		newFormFieldsData[fieldIndex].subfields[subfieldIndex].value = option;

		this.setState(
			{
				...this.state,
				specificUserForm: {
					...this.state.specificUserForm,
				},
			},
			() => this.handleEditForm()
		);
	};

	/****************************************************************************/

	renderSidebar = () => {
		const { currentForm, checkList } = this.state;

		let checkListRender;
		if (checkList.loaded) {
			checkListRender = checkList.data.map((item) =>
				this.renderCheckListItem(item)
			);
		} else if (checkList.loading) {
			checkListRender = this.renderLoading();
		}

		let sidebar;
		sidebar = (
			<div className="admin-sidebar user-sidebar">
				<Col>
					<Row noGutters className="admin-logo-row">
						<img className="admin-logo" src={fiLogo} />
					</Row>

					<Row noGutters className="admin-system-row">
						<div className="system-header">
							REGISTRATION CHECKLIST
						</div>
					</Row>

					<ListGroup variant="flush" className="admin-list-group">
						{this.renderConfirmEmailItem()}

						{checkListRender}
					</ListGroup>

					<Row noGutters className="progress-bar-row">
						{this.renderProgressBar()}
					</Row>
				</Col>
			</div>
		);

		return sidebar;
	};

	renderDeleteAccountButton = () => {
		return (
			<Button
				onClick={() =>
					this.props.deleteAccountRequest(this.props.account.data._id)
				}
			>
				Delete Account
			</Button>
		);
	};

	renderConfirmEmailItem = () => {
		const { currentForm, account } = this.state;

		const emailConfirmed = account.loaded
			? account.data.emailConfirmed
			: false;

		let statusIcon;
		if (emailConfirmed) {
			statusIcon = (
				<AiFillCheckCircle className="registration-icon confirmed" />
			);
		} else {
			statusIcon = (
				<AiFillExclamationCircle className="registration-icon pending" />
			);
		}

		return (
			<ListGroup.Item
				onClick={() => this.handleSelectSection("confirm")}
				active={currentForm === "confirm"}
				className="registration-list-item"
			>
				<Row noGutters className="registration-checklist-item-row">
					{statusIcon}
					<div className="registration-checklist-name">
						Confirm your email
					</div>
				</Row>
			</ListGroup.Item>
		);
	};

	renderCheckListItem = (item) => {
		const { _id, name, status } = item;
		const { currentForm } = this.state;

		let statusIcon;
		if (status === "pending") {
			statusIcon = (
				<AiFillExclamationCircle className="registration-icon pending" />
			);
		} else {
			statusIcon = (
				<AiFillCheckCircle className="registration-icon confirmed" />
			);
		}

		return (
			<ListGroup.Item
				key={_id}
				active={currentForm === name}
				onClick={() => this.handleSelectSection(name, _id)}
				className="registration-list-item"
			>
				<Row noGutters className="registration-checklist-item-row">
					{statusIcon}
					<div className="registration-checklist-name">{name}</div>
				</Row>
			</ListGroup.Item>
		);
	};

	renderSpecificUserForm = () => {
		const { data, saving, validity, validated } =
			this.state.specificUserForm;
		const { fields, _id, name, status, onSubmit } = data;

		const renderFields = fields.map((field, index) =>
			this.renderIndividualField(field, index)
		);

		let savingText;
		if (saving) {
			savingText = (
				<div className="form-saving-text">
					<Spinner animation="border" size="sm" />
					Saving...
				</div>
			);
		} else {
			savingText = "";
		}

		let content;
		if (status == "pending") {
			content = (
				<div className="registration-forms-table-box">
					<Row noGutters className="registration-forms-table-row">
						<div className="registration-forms-table-col">
							<Row
								noGutters
								className="registration-form-row-header"
							>
								<div className="registration-header">
									<h4>{name}</h4>

									<div className="registration-header-subtext">
										( * indicates a required field )
									</div>
								</div>
								{savingText}
							</Row>

							<Form
								validated={validated}
								noValidate
								onSubmit={this.handleSubmitForm}
							>
								{renderFields}

								{onSubmit !== "identitymindplugin" &&
									onSubmit !== "idmpluginpt" && (
										<Row
											noGutters
											className="registration-submit-button-row"
										>
											<Button
												type="submit"
												className="teal-button registration-submit-button"
											>
												Submit
											</Button>
										</Row>
									)}
							</Form>
						</div>
					</Row>
				</div>
			);
		} else {
			content = (
				<div className="registration-confirm-email">
					<Row noGutters className="registration-row">
						<Col lg={2} />
						<Col className="registration-column-confirmation">
							<h4>Thank you for submitting this form.</h4>
						</Col>
						<Col lg={2} />
					</Row>
				</div>
			);
		}

		return content;
	};

	renderIndividualField = (field, index) => {
		const { fieldName, fieldType, required, value, fieldDescription } =
			field;

		let className;
		if (fieldType == "display") {
			className = "registration-display-subfield editing-role-section";
		} else if (fieldType == "idmplugin") {
			className = "registration-idmplugin-subfield";
		} else {
			className = "registration-individual-subfield editing-role-section";
		}

		let formControl;
		if (fieldType === "string") {
			formControl = this.renderStringInput(field, index);
		} else if (fieldType === "number") {
			formControl = this.renderNumberInput(field, index);
		} else if (fieldType === "file") {
			formControl = this.renderFileUploader(field, index);
		} else if (fieldType === "paragraph") {
			formControl = this.renderParagraphInput(field, index);
		} else if (fieldType === "image") {
			formControl = this.renderFileUploader(field, index);
		} else if (fieldType === "document") {
			formControl = this.renderFileUploader(field, index);
		} else if (fieldType === "dropdown") {
			formControl = this.renderDropdown(field, index);
		} else if (fieldType === "date") {
			formControl = this.renderDatePicker(field, index);
		} else if (fieldType === "location") {
			formControl = this.renderLocationInput(field, index);
		} else if (fieldType === "e-signature") {
			formControl = this.renderSignatureInput(field, index);
		} else if (fieldType === "branch") {
			formControl = this.renderBranch(field, index);
		} else if (fieldType === "file") {
			formControl = this.renderFileUploader(field, index);
		} else if (fieldType === "checkbox") {
			formControl = this.renderCheckbox(field, index);
		} else if (fieldType == "idmplugin") {
			formControl = this.renderIDMPlugin(field, index);
		}

		let renderFieldName;
		if (required) {
			renderFieldName = fieldName + " *";
		} else {
			renderFieldName = fieldName;
		}

		if (fieldType == "idmplugin") {
			renderFieldName = "";
		}

		return (
			<Form.Group key={fieldName + "-" + index} className={className}>
				<Row noGutters>
					<Col>
						<Row noGutters className="editing-role-form-row">
							<Col>
								<b>{renderFieldName}</b>
								<div className="field-description">
									{fieldDescription
										? ReactHtmlParser(fieldDescription)
										: ""}
								</div>
							</Col>
						</Row>
						<Row noGutters className={fieldType}>
							{formControl}
						</Row>
					</Col>
				</Row>
			</Form.Group>
		);
	};

	renderIndividualSubfieldField = (
		field,
		index,
		subfieldIndex,
		branchIndex
	) => {
		const { fieldName, fieldType, required, value, fieldDescription } =
			field;

		let formControl;
		if (fieldType === "string") {
			formControl = this.renderStringInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "number") {
			formControl = this.renderNumberInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "file") {
			formControl = this.renderFileUploader(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "paragraph") {
			formControl = this.renderParagraphInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "image") {
			formControl = this.renderFileUploader(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "document") {
			formControl = this.renderFileUploader(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "dropdown") {
			formControl = this.renderDropdown(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "date") {
			formControl = this.renderDatePicker(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "location") {
			formControl = this.renderLocationInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "e-signature") {
			formControl = this.renderSignatureInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		}

		let renderFieldName;
		if (required) {
			renderFieldName = fieldName + " *";
		} else {
			renderFieldName = fieldName;
		}

		return (
			<Form.Group
				key={fieldName + "-" + index}
				className="registration-individual-subfield editing-role-section"
			>
				<Row noGutters>
					<Col>
						<Row noGutters className="editing-role-form-row">
							<Col>
								<b>{renderFieldName || "Unnamed Field"}</b>
								<div className="field-description">
									{fieldDescription
										? ReactHtmlParser(fieldDescription)
										: ""}
								</div>
							</Col>
						</Row>
						<Row noGutters>{formControl}</Row>
					</Col>
				</Row>
			</Form.Group>
		);
	};

	renderCheckbox = (field, fieldIndex) => {
		const { options, subfields, required } = field;
		let renderOptions;
		if (options.length > 0) {
			renderOptions = options.map((option, optionIndex) =>
				this.renderCheckboxOption(option, optionIndex, fieldIndex)
			);
		}

		let renderSubfields;
		if (subfields.length > 0) {
			renderSubfields = subfields.map((subfield, subfieldIndex) =>
				this.renderCheckboxSubfield(
					subfield,
					subfieldIndex,
					fieldIndex,
					options,
					required
				)
			);
		}

		return (
			<Table size="sm" className="checkbox-table">
				<thead>
					<tr>
						<th />
						{renderOptions}
					</tr>
				</thead>

				<tbody>{renderSubfields}</tbody>
			</Table>
		);
	};

	renderCheckboxOption = (option, optionIndex, fieldIndex) => {
		return (
			<th
				className="checkbox-table-th"
				key={`preview-checkboxOption ${optionIndex}, ${fieldIndex}`}
			>
				{option}
			</th>
		);
	};

	renderCheckboxSubfield = (
		subfield,
		subfieldIndex,
		fieldIndex,
		options,
		required
	) => {
		let renderOptions;
		if (options.length > 0) {
			// This sets the required status of at least one of the checkboxes to required if it is selected and the rest to false
			let optionsRequired = [];
			let noSubfieldValues = 0;
			for (let i = 0; i < options.length; i++) {
				if (options[i] == subfield.value) {
					optionsRequired.push(true);
				} else {
					optionsRequired.push(false);
					noSubfieldValues += 1;
				}
			}

			if (noSubfieldValues == optionsRequired.length) {
				for (let i = 0; i < optionsRequired.length; i++) {
					optionsRequired[i] = true;
				}
			}

			if (optionsRequired.length == options.length)
				renderOptions = options.map((option, optionIndex) =>
					this.renderCheckboxSubfieldOptions(
						option,
						optionIndex,
						subfieldIndex,
						fieldIndex,
						subfield.value,
						optionsRequired[optionIndex]
					)
				);
		}

		return (
			<tr
				className="checkbox-table-tr"
				key={`preview-checkboxSubfield ${subfieldIndex}, ${fieldIndex}`}
			>
				<td>{subfield.fieldName}</td>
				{renderOptions}
			</tr>
		);
	};

	renderCheckboxSubfieldOptions = (
		option,
		optionIndex,
		subfieldIndex,
		fieldIndex,
		value,
		required
	) => {
		const checked =
			this.state.specificUserForm.data.fields[fieldIndex].subfields[
				subfieldIndex
			].value == option
				? true
				: false;
		return (
			<td
				className="checkbox-table-td"
				key={`preview-checkboxSubfieldOption ${optionIndex}, ${subfieldIndex}, ${fieldIndex}`}
			>
				<FormCheck
					required={required}
					checked={checked}
					onChange={() =>
						this.handleChangeCheckboxSubfieldValue(
							option,
							subfieldIndex,
							fieldIndex
						)
					}
				/>
			</td>
		);
	};

	renderBranch = (field, index) => {
		const { fieldName, fieldType, required, subfields, value } = field;
		const { validity } = this.state.specificUserForm;

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		} else {
			isInvalid = false;
		}

		const branchOptions = subfields;

		let branchSubfields = [];
		if (value) {
			let subfieldIndex = value;
			let chosenBranch;

			for (let i = 0; i < branchOptions.length; i++) {
				if (i == value) {
					chosenBranch = branchOptions[i];
					subfieldIndex = i;
				}
			}

			const data = chosenBranch.data;
			branchSubfields = data.map((subfieldData, subfieldDataIndex) =>
				this.renderIndividualSubfieldField(
					subfieldData,
					index,
					subfieldDataIndex,
					subfieldIndex
				)
			);
		}

		return (
			<Col className="registration-form-control-column">
				<Form.Control
					isInvalid={
						isInvalid ||
						(required && !value) ||
						(required && value.length === 0)
					}
					size="sm"
					as="select"
					key={fieldName + "-" + index}
					required={required}
					value={value}
					onChange={(e) => this.handleOnChangeFormField(e, index)}
					className="common-admin-form-control"
				>
					<option
						value=""
						disabled={true}
						selected={
							(required && !value) ||
							(required && value.length === 0)
						}
					>
						Select an option...
					</option>
					{branchOptions.map((option, optionIndex) =>
						this.renderBranchOption(option, optionIndex)
					)}
				</Form.Control>

				<Row noGutters className="registration-branch-subfields">
					{branchSubfields}
				</Row>
			</Col>
		);
	};

	renderBranchOption = (option, optionIndex) => (
		<option key={option.name} value={optionIndex}>
			{option.name || "Unnamed Option"}
		</option>
	);

	renderLocationInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;
		const { validity } = this.state.specificUserForm;

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		} else {
			isInvalid = false;
		}

		let defaultSelected;
		if (value) {
			defaultSelected = [value];
		}

		let className;
		if (isInvalid) {
			className = "registration-form-typeahead-row-invalid";
		} else {
			className = "registration-form-typeahead-row";
		}

		return (
			<Col className="registration-form-control-column">
				<div className={className}>
					<Typeahead
						id="typeahead"
						clearButton
						value={value}
						className="registration-form-control-typeahead"
						defaultSelected={defaultSelected}
						options={this.locationOptions}
						onChange={(e) =>
							this.handleOnChangeLocation(
								e,
								index,
								subfieldDataIndex,
								subfieldIndex
							)
						}
					/>
				</div>
			</Col>
		);
	};

	renderStringInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;
		const { validity } = this.state.specificUserForm;

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		} else {
			isInvalid = false;
		}

		return (
			<Col className="registration-form-control-column">
				<Form.Control
					isInvalid={isInvalid}
					size="sm"
					value={value}
					key={fieldName + "-" + index}
					required={required}
					onChange={(e) =>
						this.handleOnChangeFormField(
							e,
							index,
							subfieldDataIndex,
							subfieldIndex
						)
					}
					className="common-admin-form-control"
				/>
			</Col>
		);
	};

	renderNumberInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;
		const { validity } = this.state.specificUserForm;

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		}
		return (
			<Col className="registration-form-control-column">
				<Form.Control
					isInvalid={
						isInvalid ||
						(required && !value) ||
						(required && value.length === 0)
					}
					size="sm"
					type="number"
					min={0}
					key={fieldName + "-" + index}
					required={required}
					value={value}
					onKeyDown={(evt) =>
						!evt.key.match(/^\d*(\.\d+)?$/g) &&
						evt.key !== "Backspace" &&
						evt.preventDefault()
					}
					onChange={(e) => {
						this.handleOnChangeFormField(
							e,
							index,
							subfieldDataIndex,
							subfieldIndex,
							fieldType
						);
					}}
					className="common-admin-form-control"
				/>
			</Col>
		);
	};

	renderParagraphInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;
		const { validity } = this.state.specificUserForm;

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		}

		return (
			<Col className="registration-form-control-column">
				<Form.Control
					isInvalid={isInvalid}
					size="sm"
					as="textarea"
					value={value}
					key={fieldName + "-" + index}
					required={required}
					onChange={(e) =>
						this.handleOnChangeFormField(
							e,
							index,
							subfieldDataIndex,
							subfieldIndex
						)
					}
					className="common-admin-form-control"
				/>
			</Col>
		);
	};

	renderImageUploader = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;
		const { validity } = this.state.specificUserForm;

		let isInvalid;
		if (validity === false && required && !value) {
			isInvalid = true;
		}

		let content;
		if (value) {
			content = (
				<div className="registration-display-image-container">
					<IoMdClose
						onClick={() =>
							this.handleClearFile(
								value,
								index,
								subfieldDataIndex,
								subfieldIndex
							)
						}
						className="registration-display-image-cancel-icon"
					/>
					<img className="registration-display-image" src={value} />
				</div>
			);
		} else {
			content = (
				<Col className="registration-form-control-column">
					<Form.File
						custom
						isInvalid={isInvalid}
						onChange={(e) =>
							this.handleSelectFile(
								e,
								index,
								subfieldDataIndex,
								subfieldIndex
							)
						}
						label="Choose an image..."
						className="registration-form-file-control"
						key={fieldName + "-" + index}
					/>
				</Col>
			);
		}

		return content;
	};

	renderDocumentUploader = (
		field,
		index,
		subfieldDataIndex,
		subfieldIndex
	) => {
		const { fieldName, fieldType, required, value } = field;
		const { validity } = this.state.specificUserForm;

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		}

		let content;
		if (value) {
			content = (
				<div className="registration-display-pdf-container">
					<IoMdClose
						onClick={() =>
							this.handleClearFile(
								value,
								index,
								subfieldDataIndex,
								subfieldIndex
							)
						}
						className="registration-display-image-cancel-icon"
					/>

					<a
						target="_blank"
						href={value}
						className="registration-document-preview"
					>
						Click here to view your document
					</a>
				</div>
			);
		} else {
			content = (
				<Col className="registration-form-control-column">
					<Form.File
						custom
						isInvalid={isInvalid}
						onChange={(e) =>
							this.handleSelectFile(
								e,
								index,
								subfieldDataIndex,
								subfieldIndex
							)
						}
						label="Upload a file from your computer..."
						className="registration-form-file-control"
						key={fieldName + "-" + index}
					/>
				</Col>
			);
		}

		return content;
	};

	renderFileUploader = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;
		const { validity } = this.state.specificUserForm;
		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		}

		let content;
		if (value) {
			if (value.includes(".png") || value.includes(".jpg")) {
				content = (
					<div className="registration-display-image-container">
						<IoMdClose
							onClick={() =>
								this.handleClearFile(
									value,
									index,
									subfieldDataIndex,
									subfieldIndex
								)
							}
							className="registration-display-image-cancel-icon"
						/>
						<img
							className="registration-display-image"
							src={value}
						/>
					</div>
				);
			} else if (value.includes(".pdf")) {
				content = (
					<div className="registration-display-pdf-container">
						<IoMdClose
							onClick={() =>
								this.handleClearFile(
									value,
									index,
									subfieldDataIndex,
									subfieldIndex
								)
							}
							className="registration-display-image-cancel-icon"
						/>

						<a
							target="_blank"
							href={value}
							className="registration-document-preview"
						>
							Click here to view your document
						</a>
					</div>
				);
			} else {
				content = (
					<div className="registration-display-pdf-container">
						<IoMdClose
							onClick={() =>
								this.handleClearFile(
									value,
									index,
									subfieldDataIndex,
									subfieldIndex
								)
							}
							className="registration-display-image-cancel-icon"
						/>

						<a
							target="_blank"
							href={value}
							className="registration-document-preview"
						>
							Click here to view this file
						</a>
					</div>
				);
			}
		} else {
			content = (
				<Col className="registration-form-control-column">
					<Form.File
						custom
						isInvalid={isInvalid}
						onChange={(e) =>
							this.handleSelectFile(
								e,
								index,
								subfieldDataIndex,
								subfieldIndex
							)
						}
						label="Upload a file from your computer..."
						className="registration-form-file-control"
						key={fieldName + "-" + index}
					/>
				</Col>
			);
		}

		return content;
	};

	renderDropdown = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value, options } = field;
		const { validity } = this.state.specificUserForm;

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		}

		return (
			<Col className="registration-form-control-column">
				<Form.Control
					isInvalid={
						isInvalid ||
						(required && !value) ||
						(required && value.length === 0)
					}
					size="sm"
					as="select"
					key={fieldName + "-" + index + "-" + subfieldIndex}
					noSubfieldValues={value}
					required
					onChange={(e) =>
						this.handleOnChangeFormField(
							e,
							index,
							subfieldDataIndex,
							subfieldIndex
						)
					}
					className="common-admin-form-control"
				>
					<option
						value=""
						disabled={true}
						selected={
							(required && !value) ||
							(required && value.length === 0)
						}
					>
						Select an option...
					</option>
					{options.map((option) => this.renderDropdownOption(option))}
				</Form.Control>
			</Col>
		);
	};

	renderDropdownOption = (option) => (
		<option key={option} value={option}>
			{option || "Unnamed Option"}
		</option>
	);

	renderDatePicker = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value, options } = field;
		const dateValue = value ? new Date(value) : "";

		const { validity } = this.state.specificUserForm;

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		}

		let className;
		if (isInvalid) {
			className = "common-date-picker invalid";
		} else {
			className = "common-date-picker";
		}

		return (
			<Col className="registration-form-control-column">
				<DatePicker
					key={fieldName + "-" + index}
					className={className}
					value={dateValue}
					required={required}
					onChange={(e) =>
						this.handleOnChangeDateField(
							e,
							index,
							subfieldDataIndex,
							subfieldIndex
						)
					}
					format="dd/MM/yyyy"
				/>
			</Col>
		);
	};

	renderSignatureInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		let currentSignature;
		if (field.value) {
			currentSignature = (
				<img src={field.value} className="registration-sig-image" />
			);
		}

		return (
			<div className="registration-sigCanvas-container">
				<SignatureCanvas
					onEnd={() =>
						this.handleSignForm(
							index,
							subfieldDataIndex,
							subfieldIndex
						)
					}
					ref={(ref) => {
						this.sigPad = ref;
					}}
					penColor="#858796"
					canvasProps={{ className: "registration-sigCanvas" }}
				/>

				{currentSignature}

				<AiOutlineClose
					onClick={() =>
						this.handleClearSig(
							index,
							subfieldDataIndex,
							subfieldIndex
						)
					}
					className="registration-sigCanvas-clear-icon"
				/>
			</div>
		);
	};

	handleCreateUrlForIDMPlugin = (url) => {
		const form_id = this.props.specificUserForm.data._id;
		const { first_name, last_name, email } = this.props.account.data;
		const dataString = `first_name=${first_name}&&last_name=${last_name}&&email=${email}`;
		const encodedDataString = btoa(dataString);
		const fixedUrl = url + `?user_id=${form_id}&data=${encodedDataString}`;

		return fixedUrl;
	};

	renderIDMPlugin = (field, index) => {
		const { fieldName, fieldType, required, value, url } = field;
		const { validity } = this.state.specificUserForm;
		const newUrl = this.handleCreateUrlForIDMPlugin(url);
		// const newUrl = 'https://regtech.identitymind.store/viewform/vmuhz/'

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		} else {
			isInvalid = false;
		}

		return (
			<Iframe
				url={newUrl}
				className="registration-iframe"
				id="idm-iframe"
				position="relative"
				frameBorder="0"
			/>
		);
	};

	testIDM = () => {
		// window.addEventListener('message', function(e) {
		//   var key = e.message ? 'message' : 'data'
		//   var data = e[key]
		//   console.log(e.data)
		// }, false)
		// var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
		//
		// var eventer = window[eventMethod]
		//
		// var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
		//
		// eventer(messageEvent, function(e) {
		//   var url_message = `https://regtech.identitymind.store/viewform/vmuhz/`
		//
		//   if (e.origin!=url_message || e.data!='submitForm') {
		//     return
		//   }
		//
		//   console.log('event')
		// })

		window.addEventListener("message", function (message) {
			console.log(message.data);
		});
	};

	renderConfirmYourEmail = () => {
		const { emailConfirmed } = this.state.account.data;

		let content;
		if (emailConfirmed) {
			content = (
				<Col className="registration-column-confirmation">
					<h4>Your email has been successfully confirmed.</h4>
				</Col>
			);
		} else {
			content = (
				<Col className="registration-column">
					<Row noGutters className="registration-form-header">
						<h4>Email Confirmation</h4>
					</Row>

					<Row noGutters className="registration-body-text">
						You must confirm your email address using the link we
						emailed you when you created your account.
					</Row>

					<Row noGutters className="registration-body-text-footer">
						Didn't get anything? Please check your spam folder or
						<div
							className="resend-confirmation"
							onClick={this.handleResendConfirmationEmail}
						>
							resend your confirmation email
						</div>
					</Row>
				</Col>
			);
		}

		return (
			<div className="forms-table-box">
				<div className="registration-confirm-email">
					<Row noGutters className="registration-row">
						{content}
					</Row>
				</div>
			</div>
		);
	};

	renderProgressBar = () => {
		const now = this.handleDetermineProgressBarProgress();
		const label = now + "%";

		return (
			<div className="registration-progress-bar-container">
				<div className="progress-bar-label">PROGRESS</div>
				<ProgressBar
					now={now || "0%"}
					label={label}
					className="registration-progress-bar"
				/>
			</div>
		);
	};

	renderCompletedAllForms = () => {
		return (
			<div className="registration-confirm-email">
				<Row noGutters className="registration-row">
					<Col lg={2} />
					<Col className="registration-completed-all-forms">
						<h6>You have completed the registration process.</h6>
						<h6>
							Please expect an email updating you on your
							registration status shortly.
						</h6>
					</Col>
					<Col lg={2} />
				</Row>
			</div>
		);
	};

	renderCurrentSection = () => {
		const { currentForm, specificUserForm, userCompletedAllForms } =
			this.state;

		let content;
		if (!this.state.checkList.loaded) {
			return (
				<Container
					fluid
					className="registration-current-section-loading-container"
				>
					{this.renderLoading()}
				</Container>
			);
		}
		if (userCompletedAllForms) {
			content = this.renderCompletedAllForms();
		} else {
			if (currentForm === "confirm") {
				content = this.renderConfirmYourEmail();
			} else if (specificUserForm.loaded) {
				content = this.renderSpecificUserForm();
			} else if (specificUserForm.loading) {
				content = (
					<Container
						fluid
						className="registration-current-section-loading-container"
					>
						{this.renderLoading()}
					</Container>
				);
			}
		}

		return content;
	};

	renderTopbar = () => {
		const { account } = this.state;

		let displayName;
		if (account.loaded) {
			if (account.data.first_name && account.data.last_name) {
				displayName = `${account.data.first_name} ${account.data.last_name}`;
			} else {
				displayName = "My Account";
			}
		}

		const title = (
			<React.Fragment>
				{displayName}
				<AiOutlineUser />
			</React.Fragment>
		);

		return (
			<div className="admin-topbar">
				<Row noGutters className="admin-topbar-row">
					<NavDropdown className="admin-topbar-nav" title={title}>
						<NavDropdown.Item
							className="admin-topbar-login"
							onClick={this.handleLogout}
						>
							<AiOutlineLogout />
							Log out
						</NavDropdown.Item>
					</NavDropdown>
				</Row>
			</div>
		);
	};

	renderLoading = () => (
		<Row noGutters className="app-spinner">
			<Spinner animation="border" className="common-grey-spinner" />
		</Row>
	);

	render() {
		if (this.state.checkList.loaded) {
			return (
				<Container fluid className="admin-container">
					<Row noGutters style={{ flexWrap: "nowrap" }}>
						{this.renderSidebar()}
						<Col>
							{this.renderTopbar()}
							<div className="admin-content-pane">
								<Container
									fluid
									className="admin-content-container"
								>
									<Row
										noGutters
										className="registration-form-row"
									>
										{this.renderCurrentSection()}
									</Row>
								</Container>
							</div>
						</Col>
					</Row>
				</Container>
			);
		} else {
			return this.renderLoading();
		}
	}
}

export default Registration;
