import React, { PureComponent } from "react";
import {
	Nav,
	Row,
	Col,
	Button,
	FormControl,
	Container,
	ListGroup,
	// Image,
	Spinner,
	ProgressBar,
	FormFile,
	Form,
	Table,
	FormCheck,
} from "react-bootstrap";
import DatePicker from "react-date-picker";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
	AiFillCheckCircle,
	AiFillExclamationCircle,
	AiOutlineLogout,
	AiOutlineClose,
} from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import countryList from "react-select-country-list";
import SignatureCanvas from "react-signature-canvas";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";

class Preview extends PureComponent {
	constructor(props) {
		super(props);

		this.locationOptions = countryList().getLabels();

		this.state = {
			specificUserForm: {
				data: this.props.formData[0],
			},
		};
	}

	componentDidMount() {}

	handleEditForm = () => {};

	handleOnChangeFormField = (e, index, subfieldDataIndex, subfieldIndex) => {
		const newFormFieldsData = this.state.specificUserForm.data.fields;

		if (subfieldDataIndex >= 0) {
			newFormFieldsData[index].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = e.target.value;
		} else {
			newFormFieldsData[index].value = e.target.value;
		}

		this.setState({
			...this.state,
			specificUserForm: {
				...this.state.specificUserForm,
			},
		});
	};

	handleChangeCheckboxSubfieldValue = (option, subfieldIndex, fieldIndex) => {
		const newFormFieldsData = this.state.specificUserForm.data.fields;
		newFormFieldsData[fieldIndex].subfields[subfieldIndex].value = option;

		this.setState({
			...this.state,
			specificUserForm: {
				...this.state.specificUserForm,
			},
		});
	};

	handleCreateUrlForIDMPlugin = (url) => {
		const form_id = this.state.specificUserForm.data._id;
		const first_name = "Firstname";
		const last_name = "Lastname";
		const email = "fake@email.net";
		const dataString = `first_name=${first_name}&&last_name=${last_name}&&email=${email}`;
		const encodedDataString = btoa(dataString);
		const fixedUrl = url + `?user_id=${form_id}&data=${encodedDataString}`;

		return fixedUrl;
	};
	/****************************************************************************/

	renderSpecificUserForm = () => {
		const { fields, _id, name, status } = this.state.specificUserForm.data;

		const renderFields = fields.map((field, index) =>
			this.renderIndividualField(field, index)
		);

		return (
			<div className="registration-forms-table-box">
				<Row noGutters className="registration-forms-table-row">
					<div className="registration-forms-table-col">
						<Row noGutters className="registration-form-row-header">
							<div className="registration-header">
								<h4>{name}</h4>

								<div className="registration-header-subtext">
									( * indicates a required field )
								</div>
							</div>
						</Row>

						<Form>
							{renderFields}
							<Row
								noGutters
								className="registration-submit-button-row"
							>
								<Button
									onClick={(e) => e.preventDefault()}
									type="submit"
									className="teal-button registration-submit-button"
								>
									Submit
								</Button>
							</Row>
						</Form>
					</div>
				</Row>
			</div>
		);
	};

	renderIndividualField = (field, index) => {
		const { fieldName, fieldType, required, value, fieldDescription } =
			field;

		let formControl;
		if (fieldType === "string") {
			formControl = this.renderStringInput(field, index);
		} else if (fieldType === "number") {
			formControl = this.renderNumberInput(field, index);
		} else if (fieldType === "paragraph") {
			formControl = this.renderParagraphInput(field, index);
		} else if (fieldType === "image") {
			formControl = this.renderFileUploader(field, index);
		} else if (fieldType === "document") {
			formControl = this.renderFileUploader(field, index);
		} else if (fieldType === "dropdown") {
			formControl = this.renderDropdown(field, index);
		} else if (fieldType === "date") {
			formControl = this.renderDatePicker(field, index);
		} else if (fieldType === "location") {
			formControl = this.renderLocationInput(field, index);
		} else if (fieldType === "e-signature") {
			formControl = this.renderSignatureInput(field, index);
		} else if (fieldType === "branch") {
			formControl = this.renderBranch(field, index);
		} else if (fieldType === "file") {
			formControl = this.renderFileUploader(field, index);
		} else if (fieldType === "checkbox") {
			formControl = this.renderCheckbox(field, index);
		} else if (fieldType == "idmplugin") {
			formControl = this.renderIDMPlugin(field, index);
		}

		let renderFieldName;
		if (required) {
			renderFieldName = fieldName + " *";
		} else {
			renderFieldName = fieldName;
		}

		if (fieldType == "idmplugin") {
			renderFieldName = "";
		}

		let className;
		if (fieldType == "display") {
			className = "registration-display-subfield editing-role-section";
		} else if (fieldType == "idmplugin") {
			className = "registration-idmplugin-subfield";
		} else {
			className = "registration-individual-subfield editing-role-section";
		}

		return (
			<Form.Group key={fieldName + "-" + index} className={className}>
				<Row noGutters>
					<Col>
						<Row noGutters className="editing-role-form-row">
							<Col>
								<b>{renderFieldName}</b>
								<div className="field-description">
									{fieldDescription
										? ReactHtmlParser(fieldDescription)
										: ""}
								</div>
							</Col>
						</Row>
						<Row noGutters>{formControl}</Row>
					</Col>
				</Row>
			</Form.Group>
		);
	};

	renderFileUploader = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;

		return (
			<Col className="registration-form-control-column">
				<Form.File
					custom
					label="Upload a file from your computer..."
					className="registration-form-file-control"
					key={fieldName + "-" + index}
				/>
			</Col>
		);
	};

	renderIDMPlugin = (field, index) => {
		const { fieldName, fieldType, required, value, url } = field;
		const { validity } = this.state.specificUserForm;
		const newUrl = this.handleCreateUrlForIDMPlugin(url);

		let isInvalid;
		if (validity === false && required === true) {
			isInvalid = true;
		} else {
			isInvalid = false;
		}

		return (
			<Iframe
				url={newUrl}
				className="registration-iframe"
				position="relative"
				frameBorder="0"
			/>
		);
	};

	renderIndividualSubfieldField = (
		field,
		index,
		subfieldIndex,
		branchIndex
	) => {
		const { fieldName, fieldType, required, value, fieldDescription } =
			field;

		let formControl;
		if (fieldType === "string") {
			formControl = this.renderStringInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "number") {
			formControl = this.renderNumberInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "paragraph") {
			formControl = this.renderParagraphInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "image") {
			formControl = this.renderFileUploader(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "document") {
			formControl = this.renderFileUploader(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "dropdown") {
			formControl = this.renderDropdown(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "date") {
			formControl = this.renderDatePicker(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "location") {
			formControl = this.renderLocationInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "e-signature") {
			formControl = this.renderSignatureInput(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		} else if (fieldType === "file") {
			formControl = this.renderFileUploader(
				field,
				index,
				subfieldIndex,
				branchIndex
			);
		}

		let renderFieldName;
		if (required) {
			renderFieldName = fieldName + " *";
		} else {
			renderFieldName = fieldName;
		}

		return (
			<Form.Group
				key={fieldName + "-" + index}
				className="registration-individual-subfield editing-role-section"
			>
				<Row noGutters>
					<Col>
						<Row noGutters className="editing-role-form-row">
							<Col>
								<b>{renderFieldName || "Unnamed Field"}</b>
								<div className="field-description">
									{fieldDescription
										? ReactHtmlParser(fieldDescription)
										: ""}
								</div>
							</Col>
						</Row>
						<Row noGutters>{formControl}</Row>
					</Col>
				</Row>
			</Form.Group>
		);
	};

	renderBranch = (field, index) => {
		const { fieldName, fieldType, required, subfields, value } = field;

		const branchOptions = subfields;
		let branchSubfields = [];
		if (value && value < branchOptions.length) {
			let chosenBranch;
			let subfieldIndex;
			for (let i = 0; i < branchOptions.length; i++) {
				if (i == value) {
					chosenBranch = branchOptions[i];
					subfieldIndex = i;
				}
			}
			const data = chosenBranch.data;
			branchSubfields = data.map((subfieldData, subfieldDataIndex) =>
				this.renderIndividualSubfieldField(
					subfieldData,
					index,
					subfieldDataIndex,
					subfieldIndex
				)
			);
		}

		return (
			<Col className="registration-form-control-column">
				<Row noGutters>
					<Form.Control
						size="sm"
						as="select"
						key={fieldName + "-" + index}
						required
						onChange={(e) => this.handleOnChangeFormField(e, index)}
						className="common-admin-form-control"
					>
						<option value="">Select an option...</option>
						{branchOptions.map((option, optionIndex) =>
							this.renderBranchOption(option, optionIndex)
						)}
					</Form.Control>
				</Row>
				<Row noGutters className="registration-branch-subfields">
					{branchSubfields}
				</Row>
			</Col>
		);
	};

	renderBranchOption = (option, optionIndex) => (
		<option key={option.name} value={optionIndex}>
			{option.name || "Unnamed Option"}
		</option>
	);

	renderCheckbox = (field, fieldIndex) => {
		const { options, subfields, required } = field;
		let renderOptions;
		if (options.length > 0) {
			renderOptions = options.map((option, optionIndex) =>
				this.renderCheckboxOption(option, optionIndex, fieldIndex)
			);
		}

		let renderSubfields;
		if (subfields.length > 0) {
			renderSubfields = subfields.map((subfield, subfieldIndex) =>
				this.renderCheckboxSubfield(
					subfield,
					subfieldIndex,
					fieldIndex,
					options,
					required
				)
			);
		}

		return (
			<Table size="sm" className="checkbox-table">
				<thead>
					<tr>
						<th />
						{renderOptions}
					</tr>
				</thead>

				<tbody>{renderSubfields}</tbody>
			</Table>
		);
	};

	renderCheckboxOption = (option, optionIndex, fieldIndex) => {
		return (
			<th
				className="checkbox-table-th"
				key={`preview-checkboxOption ${optionIndex}, ${fieldIndex}`}
			>
				{option}
			</th>
		);
	};

	renderCheckboxSubfield = (
		subfield,
		subfieldIndex,
		fieldIndex,
		options,
		required
	) => {
		let renderOptions;
		if (options.length > 0) {
			renderOptions = options.map((option, optionIndex) =>
				this.renderCheckboxSubfieldOptions(
					option,
					optionIndex,
					subfieldIndex,
					fieldIndex,
					subfield.value,
					required
				)
			);
		}
		return (
			<tr
				className="checkbox-table-tr"
				key={`preview-checkboxSubfield ${subfieldIndex}, ${fieldIndex}`}
			>
				<td>{subfield.fieldName}</td>
				{renderOptions}
			</tr>
		);
	};

	renderCheckboxSubfieldOptions = (
		option,
		optionIndex,
		subfieldIndex,
		fieldIndex,
		value,
		required
	) => {
		const checked = value == option ? true : false;
		return (
			<td
				className="checkbox-table-td"
				key={`preview-checkboxSubfieldOption ${optionIndex}, ${subfieldIndex}, ${fieldIndex}`}
			>
				<FormCheck
					required={required}
					checked={checked}
					onChange={() =>
						this.handleChangeCheckboxSubfieldValue(
							option,
							subfieldIndex,
							fieldIndex
						)
					}
				/>
			</td>
		);
	};

	renderLocationInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;

		let defaultSelected;
		if (value) {
			defaultSelected = [value];
		}

		return (
			<Col className="registration-form-control-column">
				<div className="registration-form-typeahead-row">
					<Typeahead
						id="typeahead"
						clearButton
						value={value}
						className="registration-form-control-typeahead"
						defaultSelected={defaultSelected}
						options={this.locationOptions}
					/>
				</div>
			</Col>
		);
	};

	renderStringInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;

		return (
			<Col className="registration-form-control-column">
				<Form.Control
					size="sm"
					key={fieldName + "-" + index}
					required={required}
					className="common-admin-form-control"
				/>
			</Col>
		);
	};

	renderNumberInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;

		return (
			<Col className="registration-form-control-column">
				<Form.Control
					size="sm"
					type="number"
					key={fieldName + "-" + index}
					required={required}
					className="common-admin-form-control"
				/>
			</Col>
		);
	};

	renderParagraphInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;

		return (
			<Col className="registration-form-control-column">
				<Form.Control
					size="sm"
					as="textarea"
					key={fieldName + "-" + index}
					required={required}
					className="common-admin-form-control"
				/>
			</Col>
		);
	};

	renderImageUploader = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value } = field;

		let content;
		if (value) {
			content = (
				<div className="registration-display-image-container">
					<IoMdClose className="registration-display-image-cancel-icon" />
					<img className="registration-display-image" src={value} />
				</div>
			);
		} else {
			content = (
				<Col className="registration-form-control-column">
					<Form.File
						custom
						label="Choose an image..."
						className="registration-form-file-control"
						key={fieldName + "-" + index}
					/>
				</Col>
			);
		}

		return content;
	};

	renderDocumentUploader = (
		field,
		index,
		subfieldDataIndex,
		subfieldIndex
	) => {
		const { fieldName, fieldType, required, value } = field;

		let content;
		if (value) {
			content = (
				<div className="registration-display-pdf-container">
					<IoMdClose className="registration-display-image-cancel-icon" />

					<a
						target="_blank"
						href={value}
						className="registration-document-preview"
					>
						Click here to view your document
					</a>
				</div>
			);
		} else {
			content = (
				<Col className="registration-form-control-column">
					<Form.File
						custom
						label="Upload a file from your computer..."
						className="registration-form-file-control"
						key={fieldName + "-" + index}
					/>
				</Col>
			);
		}

		return content;
	};

	renderDropdown = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value, options } = field;

		return (
			<Col className="registration-form-control-column">
				<Form.Control
					size="sm"
					as="select"
					key={fieldName + "-" + index + "-" + subfieldIndex}
					required
					className="common-admin-form-control"
				>
					<option>Select an option...</option>
					{options.map((option) => this.renderDropdownOption(option))}
				</Form.Control>
			</Col>
		);
	};

	renderDropdownOption = (option) => (
		<option key={option} value={option}>
			{option || "Unnamed Option"}
		</option>
	);

	renderDatePicker = (field, index, subfieldDataIndex, subfieldIndex) => {
		const { fieldName, fieldType, required, value, options } = field;
		const dateValue = value ? new Date(value) : "";

		return (
			<Col className="registration-form-control-column">
				<DatePicker
					key={fieldName + "-" + index}
					className="common-date-picker"
					required={required}
					format="dd/MM/yyyy"
					calendarType="US"
				/>
			</Col>
		);
	};

	renderSignatureInput = (field, index, subfieldDataIndex, subfieldIndex) => {
		let currentSignature;
		if (field.value) {
			currentSignature = (
				<img src={field.value} className="registration-sig-image" />
			);
		}
		return (
			<div className="registration-sigCanvas-container">
				<SignatureCanvas
					onEnd={() =>
						this.handleSignForm(
							index,
							subfieldDataIndex,
							subfieldIndex
						)
					}
					ref={(ref) => {
						this.sigPad = ref;
					}}
					penColor="#858796"
					canvasProps={{ className: "registration-sigCanvas" }}
				/>
			</div>
		);
	};

	handleSignForm = (index, subfieldDataIndex, subfieldIndex) => {
		const value = this.sigPad.getCanvas().toDataURL("image/png");
		const newFormFieldsData = this.state.specificUserForm.data.fields;

		if (subfieldDataIndex >= 0) {
			newFormFieldsData[index].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = value;
		} else {
			newFormFieldsData[index].value = value;
		}

		this.setState(
			{
				...this.state,
				specificUserForm: {
					...this.state.specificUserForm,
				},
			},
			() => this.handleEditForm()
		);
	};

	handleClearSig = (index, subfieldDataIndex, subfieldIndex) => {
		this.sigPad.clear();

		const newFormFieldsData = this.state.specificUserForm.data.fields;
		if (subfieldDataIndex >= 0) {
			newFormFieldsData[index].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = "";
		} else {
			newFormFieldsData[index].value = "";
		}

		this.setState(
			{
				...this.state,
				specificUserForm: {
					...this.state.specificUserForm,
				},
			},
			() => this.handleEditForm()
		);
	};

	render() {
		return (
			<div className="forms-container">
				{this.renderSpecificUserForm()}
			</div>
		);
	}
}

export default Preview;
