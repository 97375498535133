import React, { PureComponent } from 'react'
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Spinner,
} from 'react-bootstrap'
import { NavLink } from "react-router-dom"

class ConfirmEmail extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      emailConfirmed: {
        data: {},
        loading: false,
        confirmed: false,
        error: ''
      }
    }
  }

  componentDidMount() {
    this.handleCheckUrlForToken()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        emailConfirmed: nextProps.emailConfirmed
      })
    }
  }

  handleCheckUrlForToken = () => {
    const { search } = this.props.history.location

    if (search) {
      const searchBreakdown = search.split('=')
      const tokenParameter = searchBreakdown[0]
      const token = searchBreakdown[1]

      if (tokenParameter === '?token' && token) {
        this.props.confirmEmailRequest(token)
      }
    }
  }

  handleResendConfirmationEmail = () => this.props.resendConfirmationEmailRequest()

  renderLoading = () => (
    <Row noGutters className='app-spinner'>
      <Spinner animation='border' variant='secondary' />
    </Row>
  )

  renderNormalContent = () => (
    <div className='login-box'>

      <Row noGutters>
        <Col className='login-content-col'>

          <Row noGutters className='forgot-header'>
            <h4>Email Confirmation</h4>
          </Row>

          <Row noGutters className='confirmation-body'>
            Before you can do anything else, you must confirm your email
            address using the link we emailed you when you completed
            registration.
          </Row>

          <Row noGutters className='confirmation-footer'>
            Didn't get anything? Please check your spam folder or
            <div
              className='resend-confirmation'
              onClick={this.handleResendConfirmationEmail}>
              resend your confirmation email
            </div>
          </Row>

        </Col>
      </Row>

    </div>
  )

  renderErrorContent = () => (
    <div className='login-box'>

      <Row noGutters>
        <Col lg={1} />
        <Col className='login-content-col'>

          <Row noGutters className='forgot-header'>
            <h4>Email Confirmation</h4>
          </Row>

          <Row noGutters className='confirmation-body'>
            Your email confirmation link may have expired...
          </Row>

          <Row noGutters className='confirmation-footer'>
            <div
              className='resend-confirmation'
              onClick={this.handleResendConfirmationEmail}>
              Click here to resend the confirmation email
            </div>
          </Row>

        </Col>
        <Col lg={1} />
      </Row>

    </div>
  )

  render() {
    const { emailConfirmed } = this.state

    let content
    if (emailConfirmed.loading) {
      content = this.renderLoading()
    } else if (emailConfirmed.error) {
      content = this.renderErrorContent()
    } else {
      content = this.renderNormalContent()
    }

    return (
      <Container fluid className='login-container'>
        {content}
      </Container>
    )
  }
}

export default ConfirmEmail
